import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  getAuth,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { TextField, Button } from "@mui/material/";
import GoogleIcon from "./btn_google.svg";
import { useAuth } from "../../firebase";
import ForgotPassword from "./ForgotPassword";
import "./SignUp.css";

function LogIn(props) {
  const { trigger } = props;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const emailRef = useRef("");
  const passwordRef = useRef("");
  const navigate = useNavigate();
  const currentUser = useAuth();
  const auth = getAuth();

  const provider = new GoogleAuthProvider();

  const handleShowForgotPassword = () => {
    setShowForgotPassword(true);
  };

  const signInWithGoogle = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        if (trigger === "post") {
          navigate("/post");
        } else {
          navigate("/");
        }
        console.log(result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleLogin = () => {
    signInWithEmailAndPassword(auth, email, password)
      .then((result) => {
        if (trigger === "post") {
          navigate("/post");
        } else {
          navigate("/");
        }
        console.log(result);
      })
      .catch((error) => {
        setEmail("");
        setPassword("");
        console.log(error.code);
        switch (error.code) {
          case "ERROR_INVALID_EMAIL":
          case "auth/invalid-email":
            setError("Your email address appears to be malformed.");
            break;
          case "ERROR_WRONG_PASSWORD":
          case "auth/wrong-password":
            setError("Your password is wrong.");
            break;
          case "ERROR_USER_NOT_FOUND":
          case "auth/user-not-found":
            setError("User with this email doesn't exist.");
            break;
          default:
            setError("An undefined Error happened.");
        }
      });
  };

  return (
    <div>
      {!currentUser && (
        <div>
          {!showForgotPassword && (
            <div className="signup">
              <h2 className="welcome-title">Welcome to Bundle</h2>
              {error && <p>{error}</p>}
              <div className="google-btn" onClick={signInWithGoogle}>
                <img alt="Sign in with Google" src={GoogleIcon} />
                <p>Log in with Google</p>
              </div>

              <div className="divider">
                <div className="line" />
                <p style={{ lineHeight: 1.2 }}>or</p>
                <div className="line" />
              </div>
              <TextField
                inputProps={{ spellCheck: "false" }}
                required
                label="Email"
                type="email"
                margin="normal"
                value={email}
                ref={emailRef}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <TextField
                required
                label="Password"
                type="password"
                margin="normal"
                value={password}
                ref={passwordRef}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />

              <Button
                onClick={handleLogin}
                variant="contained"
                style={{ marginTop: 16, marginBottom: 8 }}
                size="large"
                disabled={!email || !password}
              >
                Log In
              </Button>
              <p className="forgot-password" onClick={handleShowForgotPassword}>
                forgot password?
              </p>
            </div>
          )}
          {showForgotPassword && (
            <ForgotPassword showForgotPassword={showForgotPassword} />
          )}
        </div>
      )}
      {currentUser && (
        <div className="signup">
          <div>Currently logged in as: {currentUser?.email} </div>
          <h1>{currentUser?.displayName}</h1>
          <h1>{currentUser?.email}</h1>
          <img alt="" src={currentUser?.photoURL} />
        </div>
      )}
    </div>
  );
}

export default LogIn;
