import React from "react";
import CreatePost from "./CreatePost";
import { subcategories } from "../../lookups/categories";

function PostJob() {
  return (
    <CreatePost
      category="job"
      categoryLabel="Job"
      category1Label="Industry"
      category1Options={subcategories.job}
      category2Label="NA"
      postTitleLabel="Company"
      postSubTitleLabel="Job Title"
      postDescriptionLabel="Job Descrition"
      postWebsiteUrlLabel="Application Link"
      postWebsiteUrlLabel2="Job Posting, Google Form, Calendar Link, etc."
      postAmountTypes={[
        { value: "year", label: "Annual" },
        { value: "hour", label: "hourly" },
      ]}
    />
  );
}

export default PostJob;
