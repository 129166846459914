import React, { useState, useRef } from "react";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { TextField, Button } from "@mui/material/";
import "./SignUp.css";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [showSuccess, setShowSuccess] = useState(true);
  const emailRef = useRef("");
  const auth = getAuth();

  const handleSendResetLink = () => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setShowSuccess(true);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  return (
    <div className="signup">
      <h2 className="welcome-title">Forgot Password?</h2>
      {showSuccess && (
        <p className="reset-success">Success! Check your email.</p>
      )}
      <TextField
        inputProps={{ spellCheck: "false" }}
        required
        label="Email"
        type="email"
        margin="normal"
        value={email}
        ref={emailRef}
        onChange={(e) => {
          setEmail(e.target.value);
        }}
      />

      <Button
        onClick={handleSendResetLink}
        variant="contained"
        style={{ marginTop: 16, marginBottom: 8 }}
        size="large"
        disabled={!email}
      >
        Send Reset Link
      </Button>
    </div>
  );
}

export default ForgotPassword;
