import React from "react";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import ScreenLockPortraitIcon from "@mui/icons-material/ScreenLockPortrait";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import CelebrationOutlinedIcon from "@mui/icons-material/CelebrationOutlined";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import "./PostSelection.css";

const stepsStyle = {
  "&:before": {
    flex: 0,
  },
};

function PostSteps() {
  return (
    <div>
      <h1 className="post_selection_title">Posting is Easy!</h1>
      <Timeline>
        <TimelineItem sx={stepsStyle}>
          <TimelineSeparator>
            <TimelineDot color="primary">
              <VideocamOutlinedIcon />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>Take quick video with your phone</TimelineContent>
        </TimelineItem>
        <TimelineItem sx={stepsStyle}>
          <TimelineSeparator>
            <TimelineDot color="primary">
              <TimerOutlinedIcon />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            Make it short - less than 30 seconds
          </TimelineContent>
        </TimelineItem>
        <TimelineItem sx={stepsStyle}>
          <TimelineSeparator>
            <TimelineDot color="primary">
              <ScreenLockPortraitIcon />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            Hold your phone vertically and don't rotate
          </TimelineContent>
        </TimelineItem>
        <TimelineItem sx={stepsStyle}>
          <TimelineSeparator>
            <TimelineDot color="primary">
              <EmojiPeopleIcon />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>Introduce yourself at the begining</TimelineContent>
        </TimelineItem>
        <TimelineItem sx={stepsStyle}>
          <TimelineSeparator>
            <TimelineDot color="primary">
              <CelebrationOutlinedIcon />
            </TimelineDot>
          </TimelineSeparator>
          <TimelineContent>Most of all, make it fun!</TimelineContent>
        </TimelineItem>
      </Timeline>
    </div>
  );
}

export default PostSteps;
