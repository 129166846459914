export const category_main = [
	{
		value: "job",
		label: "Job",
		labelPlural: "Jobs",
		disabled: false,
	},
	{
		value: "restaurant",
		label: "Restaurant",
		labelPlural: "Restaurants",
		disabled: false,
	},
	{
		value: "pro",
		label: "Local Professional",
		labelPlural: "Local Professionals",
		disabled: false,
	},
	{
		value: "home",
		label: "Home or Apartment",
		labelPlural: "Homes or Apartments",
		disabled: false,
	},
	{
		value: "activity",
		label: "Activity",
		labelPlural: "Activities",
		disabled: false,
	},
];
export const subcategories = {
	job: [
		{
			value: "professional",
			label: "Professional",
			disabled: false,
		},
		{
			value: "food",
			label: "Restaurants & Retail",
			disabled: false,
		},
		{
			value: "skilled",
			label: "Skilled Labor",
			disabled: false,
		},
	],

	restaurant: [
		{
			value: "casual",
			label: "Casual",
			disabled: false,
		},
		{
			value: "fine",
			label: "Fine Dining",
			disabled: false,
		},
		{
			value: "bar",
			label: "Bar",
			disabled: false,
		},
	],

	pro: [
		{
			value: "real_esate_agent",
			label: "Real Estate Agent",
			disabled: false,
		},
		{
			value: "mortgage",
			label: "Mortgage Officer",
			disabled: false,
		},

		{
			value: "financial_adivisor",
			label: "Financial Advisor",
			disabled: false,
		},
		{
			value: "accountant",
			label: "Tax Accountant",
			disabled: false,
		},
		{
			value: "attorney",
			label: "Attorney",
			disabled: false,
		},
	],

	home: [
		{
			value: "buy",
			label: "Homes for Sale",
			disabled: false,
		},
		{
			value: "rent",
			label: "For Rent",
			disabled: false,
		},
	],

	activity: [
		{
			value: "outdoor",
			label: "Outdoor",
			disabled: false,
		},
		{
			value: "kids",
			label: "Kid friendly",
			disabled: false,
		},
		{
			value: "museums",
			label: "Museums",
			disabled: false,
		},
	],
};
