export const cities = [
	{
		geocode: 35620,
		city: "New York",
		state: "NY",
		lat: 40.77493,
		lon: -73.873758,
	},
	{
		geocode: 31080,
		city: "Los Angeles",
		state: "CA",
		lat: 34.108703,
		lon: -118.182753,
	},
	{
		geocode: 16980,
		city: "Chicago",
		state: "IL",
		lat: 41.823521,
		lon: -87.828297,
	},
	{
		geocode: 19100,
		city: "Dallas",
		state: "TX",
		lat: 32.849171,
		lon: -96.970489,
	},
	{
		geocode: 26420,
		city: "Houston",
		state: "TX",
		lat: 29.749593,
		lon: -95.353642,
	},
	{
		geocode: 47900,
		city: "Washington",
		state: "DC",
		lat: 38.798205,
		lon: -77.486882,
	},
	{
		geocode: 37980,
		city: "Philadelphia",
		state: "PA",
		lat: 39.894958,
		lon: -75.311982,
	},
	{
		geocode: 33100,
		city: "Miami",
		state: "FL",
		lat: 26.101828,
		lon: -80.478755,
	},
	{
		geocode: 12060,
		city: "Atlanta",
		state: "GA",
		lat: 33.693728,
		lon: -84.399911,
	},
	{
		geocode: 14460,
		city: "Boston",
		state: "MA",
		lat: 42.517606,
		lon: -71.021993,
	},
	{
		geocode: 38060,
		city: "Phoenix",
		state: "AZ",
		lat: 33.185765,
		lon: -112.067862,
	},
	{
		geocode: 41860,
		city: "San Francisco",
		state: "CA",
		lat: 37.773718,
		lon: -122.274432,
	},
	{
		geocode: 40140,
		city: "Riverside",
		state: "CA",
		lat: 34.538476,
		lon: -116.134541,
	},
	{
		geocode: 19820,
		city: "Detroit",
		state: "MI",
		lat: 42.721848,
		lon: -83.200846,
	},
	{
		geocode: 42660,
		city: "Seattle",
		state: "WA",
		lat: 47.490552,
		lon: -121.833977,
	},
	{
		geocode: 33460,
		city: "Minneapolis",
		state: "MI",
		lat: 45.096664,
		lon: -93.275361,
	},
	{
		geocode: 41740,
		city: "San Diego",
		state: "CA",
		lat: 33.023604,
		lon: -116.776117,
	},
	{
		geocode: 45300,
		city: "Tampa",
		state: "FL",
		lat: 28.120541,
		lon: -82.525188,
	},
	{
		geocode: 19740,
		city: "Denver",
		state: "CO",
		lat: 39.434789,
		lon: -104.901073,
	},
	{
		geocode: 12580,
		city: "Baltimore",
		state: "MD",
		lat: 39.304361,
		lon: -76.549501,
	},
	{
		geocode: 41180,
		city: "St. Louis",
		state: "MO",
		lat: 38.740211,
		lon: -90.345825,
	},
	{
		geocode: 36740,
		city: "Orlando",
		state: "FL",
		lat: 28.434396,
		lon: -81.356084,
	},
	{
		geocode: 16740,
		city: "Charlotte",
		state: "NC",
		lat: 35.166342,
		lon: -80.797285,
	},
	{
		geocode: 41700,
		city: "San Antonio",
		state: "TX",
		lat: 29.43306,
		lon: -98.606973,
	},
	{
		geocode: 38900,
		city: "Portland",
		state: "OR",
		lat: 45.600622,
		lon: -122.484378,
	},
	{
		geocode: 40900,
		city: "Sacramento",
		state: "CA",
		lat: 38.790272,
		lon: -121.005643,
	},
	{
		geocode: 38300,
		city: "Pittsburgh",
		state: "PA",
		lat: 40.439525,
		lon: -79.836097,
	},
	{
		geocode: 12420,
		city: "Austin",
		state: "TX",
		lat: 30.249744,
		lon: -97.649842,
	},
	{
		geocode: 29820,
		city: "Las Vegas",
		state: "NV",
		lat: 36.21413,
		lon: -115.014398,
	},
	{
		geocode: 17140,
		city: "Cincinnati",
		state: "OH",
		lat: 39.100546,
		lon: -84.479533,
	},
	{
		geocode: 28140,
		city: "Kansas City",
		state: "MO",
		lat: 38.931857,
		lon: -94.443841,
	},
	{
		geocode: 18140,
		city: "Columbus",
		state: "OH",
		lat: 39.968562,
		lon: -82.835911,
	},
	{
		geocode: 26900,
		city: "Indianapolis",
		state: "IN",
		lat: 39.74867,
		lon: -86.212382,
	},
	{
		geocode: 17460,
		city: "Cleveland",
		state: "OH",
		lat: 41.555037,
		lon: -81.604957,
	},
	{
		geocode: 41940,
		city: "San Jose",
		state: "CA",
		lat: 36.908472,
		lon: -121.371372,
	},
	{
		geocode: 34980,
		city: "Nashville",
		state: "TN",
		lat: 36.118182,
		lon: -86.64412,
	},
	{
		geocode: 47260,
		city: "Virginia Beach",
		state: "VA",
		lat: 36.804408,
		lon: -76.449526,
	},
	{
		geocode: 39300,
		city: "Providence",
		state: "RI",
		lat: 41.70684,
		lon: -71.286687,
	},
	{
		geocode: 27260,
		city: "Jacksonville",
		state: "FL",
		lat: 30.236594,
		lon: -81.754446,
	},
	{
		geocode: 33340,
		city: "Milwaukee",
		state: "WI",
		lat: 42.912643,
		lon: -87.862312,
	},
	{
		geocode: 36420,
		city: "Oklahoma City",
		state: "OK",
		lat: 35.430968,
		lon: -97.506966,
	},
	{
		geocode: 39580,
		city: "Raleigh",
		state: "NC",
		lat: 35.756746,
		lon: -78.460441,
	},
	{
		geocode: 32820,
		city: "Memphis",
		state: "TN",
		lat: 35.024419,
		lon: -89.871971,
	},
	{
		geocode: 40060,
		city: "Richmond",
		state: "VA",
		lat: 37.411324,
		lon: -77.441816,
	},
	{
		geocode: 31140,
		city: "Louisville",
		state: "KY",
		lat: 38.305838,
		lon: -85.681645,
	},
	{
		geocode: 35380,
		city: "New Orleans",
		state: "LA",
		lat: 30.05342,
		lon: -89.934502,
	},
	{
		geocode: 41620,
		city: "Salt Lake City",
		state: "UT",
		lat: 40.572887,
		lon: -112.953253,
	},
	{
		geocode: 25540,
		city: "Hartford",
		state: "CT",
		lat: 41.71865,
		lon: -72.573919,
	},
	{
		geocode: 15380,
		city: "Buffalo",
		state: "NY",
		lat: 43.044371,
		lon: -78.777335,
	},
	{
		geocode: 13820,
		city: "Birmingham",
		state: "AL",
		lat: 33.402599,
		lon: -86.729515,
	},
	{
		geocode: 40380,
		city: "Rochester",
		state: "NY",
		lat: 43.148038,
		lon: -77.523257,
	},
	{
		geocode: 24340,
		city: "Grand Rapids",
		state: "MI",
		lat: 43.037598,
		lon: -85.778167,
	},
	{
		geocode: 46060,
		city: "Tucson",
		state: "AZ",
		lat: 32.128038,
		lon: -111.783657,
	},
	{
		geocode: 46520,
		city: "Honolulu",
		state: "HI",
		lat: 21.461365,
		lon: -158.201974,
	},
	{
		geocode: 46140,
		city: "Tulsa",
		state: "OK",
		lat: 36.254442,
		lon: -96.177298,
	},
	{
		geocode: 23420,
		city: "Fresno",
		state: "CA",
		lat: 36.761006,
		lon: -119.655019,
	},
	{
		geocode: 49340,
		city: "Worcester",
		state: "MA",
		lat: 42.217311,
		lon: -71.925535,
	},
	{
		geocode: 36540,
		city: "Omaha",
		state: "NE",
		lat: 41.290459,
		lon: -95.99997,
	},
	{
		geocode: 14860,
		city: "Bridgeport",
		state: "CT",
		lat: 41.227413,
		lon: -73.367061,
	},
	{
		geocode: 24860,
		city: "Greenville",
		state: "SC",
		lat: 34.683709,
		lon: -82.413423,
	},
	{
		geocode: 10740,
		city: "Albuquerque",
		state: "NM",
		lat: 35.123496,
		lon: -106.458392,
	},
	{
		geocode: 12540,
		city: "Bakersfield",
		state: "NY",
		lat: 35.346629,
		lon: -118.729506,
	},
	{
		geocode: 10580,
		city: "Albany",
		state: "NY",
		lat: 42.78792,
		lon: -73.942348,
	},
	{
		geocode: 28940,
		city: "Knoxville",
		state: "TN",
		lat: 36.014791,
		lon: -84.206701,
	},
	{
		geocode: 32580,
		city: "McAllen",
		state: "TX",
		lat: 26.396384,
		lon: -98.18099,
	},
	{
		geocode: 12940,
		city: "Baton Rouge",
		state: "LA",
		lat: 30.515242,
		lon: -91.126926,
	},
	{
		geocode: 21340,
		city: "El Paso",
		state: "TX",
		lat: 31.527394,
		lon: -105.521333,
	},
	{
		geocode: 35300,
		city: "New Haven",
		state: "CT",
		lat: 41.349718,
		lon: -72.900203,
	},
	{
		geocode: 10900,
		city: "Allentown",
		state: "PA",
		lat: 40.789339,
		lon: -75.398158,
	},
	{
		geocode: 37100,
		city: "Oxnard",
		state: "CA",
		lat: 34.358748,
		lon: -119.133145,
	},
	{
		geocode: 35840,
		city: "North Port",
		state: "FL",
		lat: 27.329055,
		lon: -82.414533,
	},
	{
		geocode: 17900,
		city: "Columbia",
		state: "SC",
		lat: 34.077585,
		lon: -81.037845,
	},
	{
		geocode: 19430,
		city: "Dayton",
		state: "OH",
		lat: 39.829746,
		lon: -84.140992,
	},
	{
		geocode: 16700,
		city: "Charleston",
		state: "SC",
		lat: 33.01754,
		lon: -80.009947,
	},
	{
		geocode: 44700,
		city: "Stockton",
		state: "CA",
		lat: 37.934982,
		lon: -121.272244,
	},
	{
		geocode: 24660,
		city: "Greensboro",
		state: "NC",
		lat: 36.025204,
		lon: -79.792633,
	},
	{
		geocode: 14260,
		city: "Boise City",
		state: "ID",
		lat: 43.006648,
		lon: -116.141909,
	},
	{
		geocode: 15980,
		city: "Cape Coral",
		state: "FL",
		lat: 26.563273,
		lon: -81.984182,
	},
	{
		geocode: 17820,
		city: "Colorado Springs",
		state: "CO",
		lat: 38.845507,
		lon: -104.638728,
	},
	{
		geocode: 30780,
		city: "Little Rock",
		state: "AR",
		lat: 34.755773,
		lon: -92.400589,
	},
	{
		geocode: 29460,
		city: "Lakeland",
		state: "FL",
		lat: 27.953649,
		lon: -81.693534,
	},
	{
		geocode: 19780,
		city: "Des Moines",
		state: "IA",
		lat: 41.57705,
		lon: -93.759712,
	},
	{
		geocode: 10420,
		city: "Akron",
		state: "OH",
		lat: 41.146639,
		lon: -81.35011,
	},
	{
		geocode: 44140,
		city: "Springfield",
		state: "MA",
		lat: 42.364833,
		lon: -72.619406,
	},
	{
		geocode: 39100,
		city: "Poughkeepsie",
		state: "NY",
		lat: 41.575274,
		lon: -74.033873,
	},
	{
		geocode: 36260,
		city: "Ogden",
		state: "UT",
		lat: 41.622623,
		lon: -113.060252,
	},
	{
		geocode: 31540,
		city: "Madison",
		state: "WI",
		lat: 43.083846,
		lon: -89.597745,
	},
	{
		geocode: 49180,
		city: "Winston",
		state: "NC",
		lat: 36.078852,
		lon: -80.345064,
	},
	{
		geocode: 39340,
		city: "Provo",
		state: "UT",
		lat: 39.872067,
		lon: -112.359059,
	},
	{
		geocode: 19660,
		city: "Deltona",
		state: "FL",
		lat: 29.1789,
		lon: -81.189023,
	},
	{
		geocode: 45060,
		city: "Syracuse",
		state: "NY",
		lat: 43.006516,
		lon: -76.196134,
	},
	{
		geocode: 20500,
		city: "Durham",
		state: "NC",
		lat: 36.060589,
		lon: -78.989787,
	},
	{
		geocode: 48620,
		city: "Wichita",
		state: "KS",
		lat: 37.637896,
		lon: -97.246287,
	},
	{
		geocode: 45780,
		city: "Toledo",
		state: "OH",
		lat: 41.555313,
		lon: -83.517088,
	},
	{
		geocode: 12260,
		city: "Augusta",
		state: "GA",
		lat: 33.460371,
		lon: -81.982833,
	},
	{
		geocode: 37340,
		city: "Palm Bay",
		state: "FL",
		lat: 28.298311,
		lon: -80.700333,
	},
	{
		geocode: 27140,
		city: "Jackson",
		state: "MS",
		lat: 32.42828,
		lon: -90.202388,
	},
	{
		geocode: 25420,
		city: "Harrisburg",
		state: "PA",
		lat: 40.335915,
		lon: -77.05049,
	},
	{
		geocode: 44060,
		city: "Spokane",
		state: "WA",
		lat: 48.091618,
		lon: -117.663472,
	},
	{
		geocode: 42540,
		city: "Scranton",
		state: "PA",
		lat: 41.322881,
		lon: -75.898188,
	},
	{
		geocode: 16860,
		city: "Chattanooga",
		state: "TN",
		lat: 35.049361,
		lon: -85.361158,
	},
	{
		geocode: 29540,
		city: "Lancaster",
		state: "PA",
		lat: 40.041992,
		lon: -76.250198,
	},
	{
		geocode: 33700,
		city: "Modesto",
		state: "CA",
		lat: 37.562316,
		lon: -121.002831,
	},
	{
		geocode: 38860,
		city: "Portland",
		state: "ME",
		lat: 43.651417,
		lon: -70.418375,
	},
	{
		geocode: 22220,
		city: "Fayetteville",
		state: "AR",
		lat: 36.116394,
		lon: -94.079545,
	},
	{
		geocode: 29620,
		city: "Lansing",
		state: "MI",
		lat: 42.771233,
		lon: -84.496029,
	},
	{
		geocode: 49660,
		city: "Youngstown",
		state: "OH",
		lat: 41.23646,
		lon: -80.561784,
	},
	{
		geocode: 22180,
		city: "Fayetteville",
		state: "NC",
		lat: 35.155981,
		lon: -78.931856,
	},
	{
		geocode: 30460,
		city: "Lexington",
		state: "KY",
		lat: 38.102876,
		lon: -84.438545,
	},
	{
		geocode: 37860,
		city: "Pensacola",
		state: "FL",
		lat: 30.608557,
		lon: -87.158547,
	},
	{
		geocode: 26620,
		city: "Huntsville",
		state: "AL",
		lat: 34.783262,
		lon: -86.734871,
	},
	{
		geocode: 39900,
		city: "Reno",
		state: "NV",
		lat: 40.500795,
		lon: -119.731161,
	},
	{
		geocode: 42220,
		city: "Santa Rosa",
		state: "CA",
		lat: 38.525182,
		lon: -122.92611,
	},
	{
		geocode: 34820,
		city: "Myrtle Beach",
		state: "SC",
		lat: 33.969095,
		lon: -78.612724,
	},
	{
		geocode: 38940,
		city: "Port St. Lucie",
		state: "FL",
		lat: 27.228046,
		lon: -80.425388,
	},
	{
		geocode: 29180,
		city: "Lafayette",
		state: "LA",
		lat: 29.957773,
		lon: -92.04935,
	},
	{
		geocode: 44180,
		city: "Springfield",
		state: "MO",
		lat: 37.36087,
		lon: -93.180262,
	},
	{
		geocode: 28660,
		city: "Killeen",
		state: "TX",
		lat: 31.20273,
		lon: -97.789638,
	},
	{
		geocode: 47300,
		city: "Visalia",
		state: "CA",
		lat: 36.228834,
		lon: -118.781055,
	},
	{
		geocode: 11700,
		city: "Asheville",
		state: "NC",
		lat: 35.595669,
		lon: -82.681615,
	},
	{
		geocode: 49620,
		city: "York",
		state: "PA",
		lat: 39.921751,
		lon: -76.728889,
	},
	{
		geocode: 46700,
		city: "Vallejo",
		state: "CA",
		lat: 38.267226,
		lon: -121.939594,
	},
	{
		geocode: 42200,
		city: "Santa Maria",
		state: "CA",
		lat: 34.536677,
		lon: -120.038364,
	},
	{
		geocode: 41500,
		city: "Salinas",
		state: "CA",
		lat: 36.240104,
		lon: -121.315578,
	},
	{
		geocode: 41420,
		city: "Salem",
		state: "OR",
		lat: 44.903385,
		lon: -122.901757,
	},
	{
		geocode: 33660,
		city: "Mobile",
		state: "AL",
		lat: 30.971509,
		lon: -88.20787,
	},
	{
		geocode: 39740,
		city: "Reading",
		state: "PA",
		lat: 40.413957,
		lon: -75.92686,
	},
	{
		geocode: 31700,
		city: "Manchester",
		state: "NH",
		lat: 42.911532,
		lon: -71.723055,
	},
	{
		geocode: 18580,
		city: "Corpus Christi",
		state: "TX",
		lat: 27.833597,
		lon: -97.508958,
	},
	{
		geocode: 15180,
		city: "Brownsville",
		state: "TX",
		lat: 26.102923,
		lon: -97.478958,
	},
	{
		geocode: 23060,
		city: "Fort Wayne",
		state: "IN",
		lat: 41.113116,
		lon: -85.217705,
	},
	{
		geocode: 41540,
		city: "Salisbury",
		state: "MD",
		lat: 38.399521,
		lon: -75.478262,
	},
	{
		geocode: 25060,
		city: "Gulfport",
		state: "MS",
		lat: 30.480153,
		lon: -88.999297,
	},
	{
		geocode: 22420,
		city: "Flint",
		state: "MI",
		lat: 43.021077,
		lon: -83.706372,
	},
	{
		geocode: 42340,
		city: "Savannah",
		state: "GA",
		lat: 32.109153,
		lon: -81.273107,
	},
	{
		geocode: 37900,
		city: "Peoria",
		state: "IL",
		lat: 40.708887,
		lon: -89.697466,
	},
	{
		geocode: 15940,
		city: "Canton",
		state: "OH",
		lat: 40.711051,
		lon: -81.261468,
	},
	{
		geocode: 11260,
		city: "Anchorage",
		state: "AK",
		lat: 62.212784,
		lon: -149.678222,
	},
	{
		geocode: 13140,
		city: "Beaumont",
		state: "TX",
		lat: 30.075928,
		lon: -94.202705,
	},
	{
		geocode: 43340,
		city: "Shreveport",
		state: "LA",
		lat: 32.437682,
		lon: -93.744884,
	},
	{
		geocode: 45940,
		city: "Trenton",
		state: "NJ",
		lat: 40.282503,
		lon: -74.703724,
	},
	{
		geocode: 33860,
		city: "Montgomery",
		state: "AL",
		lat: 32.365631,
		lon: -86.404585,
	},
	{
		geocode: 19340,
		city: "Davenport",
		state: "IA",
		lat: 41.39168,
		lon: -90.460076,
	},
	{
		geocode: 45220,
		city: "Tallahassee",
		state: "FL",
		lat: 30.346366,
		lon: -84.267001,
	},
	{
		geocode: 21660,
		city: "Eugene",
		state: "OR",
		lat: 43.928334,
		lon: -122.897581,
	},
	{
		geocode: 36100,
		city: "Ocala",
		state: "FL",
		lat: 29.202805,
		lon: -82.0431,
	},
	{
		geocode: 34940,
		city: "Naples",
		state: "FL",
		lat: 26.118786,
		lon: -81.400955,
	},
	{
		geocode: 11460,
		city: "Ann Arbor",
		state: "MI",
		lat: 42.252217,
		lon: -83.843423,
	},
	{
		geocode: 25860,
		city: "Hickory",
		state: "NC",
		lat: 35.814286,
		lon: -81.456575,
	},
	{
		geocode: 26580,
		city: "Huntington",
		state: "WV",
		lat: 38.406577,
		lon: -82.542646,
	},
	{
		geocode: 22660,
		city: "Fort Collins",
		state: "CO",
		lat: 40.658093,
		lon: -105.486764,
	},
	{
		geocode: 30700,
		city: "Lincoln",
		state: "NE",
		lat: 40.817429,
		lon: -96.868801,
	},
	{
		geocode: 23540,
		city: "Gainesville",
		state: "FL",
		lat: 29.453355,
		lon: -82.666257,
	},
	{
		geocode: 40420,
		city: "Rockford",
		state: "IL",
		lat: 42.331183,
		lon: -89.042914,
	},
	{
		geocode: 14500,
		city: "Boulder",
		state: "CO",
		lat: 40.094963,
		lon: -105.397704,
	},
	{
		geocode: 24540,
		city: "Greeley",
		state: "CO",
		lat: 40.555961,
		lon: -104.383666,
	},
	{
		geocode: 17980,
		city: "Columbus",
		state: "GA",
		lat: 32.419377,
		lon: -84.843709,
	},
	{
		geocode: 24580,
		city: "Green Bay",
		state: "WI",
		lat: 44.474024,
		lon: -87.99613,
	},
	{
		geocode: 43900,
		city: "Spartanburg",
		state: "SC",
		lat: 34.932014,
		lon: -81.991624,
	},
	{
		geocode: 43780,
		city: "South Bend",
		state: "IN",
		lat: 41.774981,
		lon: -86.123014,
	},
	{
		geocode: 31180,
		city: "Lubbock",
		state: "TX",
		lat: 33.470327,
		lon: -101.648825,
	},
	{
		geocode: 17300,
		city: "Clarksville",
		state: "TN",
		lat: 36.69571,
		lon: -87.624307,
	},
	{
		geocode: 40220,
		city: "Roanoke",
		state: "VA",
		lat: 37.286226,
		lon: -79.946935,
	},
	{
		geocode: 21780,
		city: "Evansville",
		state: "IN",
		lat: 38.02007,
		lon: -87.586166,
	},
	{
		geocode: 28700,
		city: "Kingsport",
		state: "TN",
		lat: 36.604161,
		lon: -82.440145,
	},
	{
		geocode: 28420,
		city: "Kennewick",
		state: "WA",
		lat: 46.364155,
		lon: -119.254927,
	},
	{
		geocode: 36500,
		city: "Olympia",
		state: "WA",
		lat: 46.935822,
		lon: -122.830152,
	},
	{
		geocode: 25180,
		city: "Hagerstown",
		state: "MD",
		lat: 39.554433,
		lon: -77.984594,
	},
	{
		geocode: 46540,
		city: "Utica",
		state: "NY",
		lat: 43.33228,
		lon: -75.173189,
	},
	{
		geocode: 20260,
		city: "Duluth",
		state: "MN",
		lat: 47.347503,
		lon: -92.155335,
	},
	{
		geocode: 18880,
		city: "Crestview",
		state: "FL",
		lat: 30.594941,
		lon: -86.358164,
	},
	{
		geocode: 30980,
		city: "Longview",
		state: "TX",
		lat: 32.431646,
		lon: -94.684833,
	},
	{
		geocode: 48900,
		city: "Wilmington",
		state: "NC",
		lat: 34.426033,
		lon: -77.889634,
	},
	{
		geocode: 42020,
		city: "San Luis Obispo",
		state: "CA",
		lat: 35.388189,
		lon: -120.448897,
	},
	{
		geocode: 32900,
		city: "Merced",
		state: "CA",
		lat: 37.194806,
		lon: -120.722802,
	},
	{
		geocode: 47380,
		city: "Waco",
		state: "TX",
		lat: 31.427226,
		lon: -97.093473,
	},
	{
		geocode: 43620,
		city: "Sioux Falls",
		state: "SD",
		lat: 43.49542,
		lon: -96.995339,
	},
	{
		geocode: 16300,
		city: "Cedar Rapids",
		state: "IA",
		lat: 42.086289,
		lon: -91.631095,
	},
	{
		geocode: 14740,
		city: "Bremerton",
		state: "WA",
		lat: 47.639595,
		lon: -122.649634,
	},
	{
		geocode: 12100,
		city: "Atlantic City",
		state: "NJ",
		lat: 39.469355,
		lon: -74.633759,
	},
	{
		geocode: 21500,
		city: "Erie",
		state: "PA",
		lat: 42.117952,
		lon: -80.096386,
	},
	{
		geocode: 42100,
		city: "Santa Cruz",
		state: "CA",
		lat: 37.012347,
		lon: -122.007789,
	},
	{
		geocode: 11100,
		city: "Amarillo",
		state: "TX",
		lat: 35.247999,
		lon: -101.90867,
	},
	{
		geocode: 46220,
		city: "Tuscaloosa",
		state: "AL",
		lat: 33.097877,
		lon: -87.799147,
	},
	{
		geocode: 35980,
		city: "Norwich",
		state: "CT",
		lat: 41.472652,
		lon: -72.108634,
	},
	{
		geocode: 17780,
		city: "College Station",
		state: "TX",
		lat: 30.754392,
		lon: -96.488537,
	},
	{
		geocode: 29700,
		city: "Laredo",
		state: "TX",
		lat: 27.760799,
		lon: -99.340752,
	},
	{
		geocode: 28020,
		city: "Kalamazoo",
		state: "MI",
		lat: 42.246266,
		lon: -85.532854,
	},
	{
		geocode: 31340,
		city: "Lynchburg",
		state: "VA",
		lat: 37.364926,
		lon: -79.216689,
	},
	{
		geocode: 16620,
		city: "Charleston",
		state: "WV",
		lat: 38.355177,
		lon: -81.620341,
	},
	{
		geocode: 49420,
		city: "Yakima",
		state: "WA",
		lat: 46.456558,
		lon: -120.740145,
	},
	{
		geocode: 22020,
		city: "Fargo",
		state: "ND",
		lat: 46.914859,
		lon: -96.960183,
	},
	{
		geocode: 13780,
		city: "Binghamton",
		state: "NY",
		lat: 42.163928,
		lon: -76.022943,
	},
	{
		geocode: 22900,
		city: "Fort Smith",
		state: "AR",
		lat: 35.463119,
		lon: -94.299741,
	},
	{
		geocode: 11540,
		city: "Appleton",
		state: "WI",
		lat: 44.290603,
		lon: -88.372326,
	},
	{
		geocode: 39150,
		city: "Prescott Valley",
		state: "AZ",
		lat: 34.631071,
		lon: -112.577225,
	},
	{
		geocode: 31420,
		city: "Macon",
		state: "GA",
		lat: 32.856216,
		lon: -83.714305,
	},
	{
		geocode: 46340,
		city: "Tyler",
		state: "TX",
		lat: 32.375105,
		lon: -95.268939,
	},
	{
		geocode: 45820,
		city: "Topeka",
		state: "KS",
		lat: 39.041389,
		lon: -95.802639,
	},
	{
		geocode: 19300,
		city: "Daphne",
		state: "AL",
		lat: 30.659218,
		lon: -87.746067,
	},
	{
		geocode: 12700,
		city: "Barnstable Town",
		state: "MA",
		lat: 41.706123,
		lon: -70.164823,
	},
	{
		geocode: 13380,
		city: "Bellingham",
		state: "WA",
		lat: 48.842653,
		lon: -121.836432,
	},
	{
		geocode: 40340,
		city: "Rochester",
		state: "MN",
		lat: 43.94981,
		lon: -92.33066,
	},
	{
		geocode: 15540,
		city: "Burlington",
		state: "VT",
		lat: 44.689115,
		lon: -73.041175,
	},
	{
		geocode: 29200,
		city: "Lafayette",
		state: "IN",
		lat: 40.482153,
		lon: -87.017112,
	},
	{
		geocode: 32780,
		city: "Medford",
		state: "OR",
		lat: 42.411621,
		lon: -122.675611,
	},
	{
		geocode: 16580,
		city: "Champaign",
		state: "IL",
		lat: 40.102988,
		lon: -88.318504,
	},
	{
		geocode: 29340,
		city: "Lake Charles",
		state: "LA",
		lat: 29.978755,
		lon: -93.253243,
	},
	{
		geocode: 16820,
		city: "Charlottesville",
		state: "VA",
		lat: 37.949084,
		lon: -78.593206,
	},
	{
		geocode: 29740,
		city: "Las Cruces",
		state: "NM",
		lat: 32.349919,
		lon: -106.834968,
	},
	{
		geocode: 25940,
		city: "Hilton Head Island",
		state: "SC",
		lat: 32.386792,
		lon: -80.830877,
	},
	{
		geocode: 12020,
		city: "Athens",
		state: "GA",
		lat: 33.943984,
		lon: -83.213897,
	},
	{
		geocode: 29420,
		city: "Lake Havasu City",
		state: "AZ",
		lat: 35.717705,
		lon: -113.749689,
	},
	{
		geocode: 17020,
		city: "Chico",
		state: "CA",
		lat: 39.665336,
		lon: -121.603209,
	},
	{
		geocode: 17860,
		city: "Columbia",
		state: "MO",
		lat: 38.986486,
		lon: -92.585189,
	},
	{
		geocode: 44100,
		city: "Springfield",
		state: "IL",
		lat: 39.828611,
		lon: -89.696084,
	},
	{
		geocode: 27740,
		city: "Johnson City",
		state: "TN",
		lat: 36.265822,
		lon: -82.332693,
	},
	{
		geocode: 26380,
		city: "Houma",
		state: "LA",
		lat: 29.33413,
		lon: -90.843674,
	},
	{
		geocode: 33740,
		city: "Monroe",
		state: "LA",
		lat: 32.734255,
		lon: -92.09955,
	},
	{
		geocode: 21140,
		city: "Elkhart",
		state: "IN",
		lat: 41.600693,
		lon: -85.863986,
	},
	{
		geocode: 27340,
		city: "Jacksonville",
		state: "NC",
		lat: 34.763109,
		lon: -77.499469,
	},
	{
		geocode: 49740,
		city: "Yuma",
		state: "AZ",
		lat: 32.773942,
		lon: -113.910905,
	},
	{
		geocode: 23580,
		city: "Gainesville",
		state: "GA",
		lat: 34.317569,
		lon: -83.8185,
	},
	{
		geocode: 22500,
		city: "Florence",
		state: "SC",
		lat: 34.153574,
		lon: -79.806164,
	},
	{
		geocode: 41060,
		city: "St. Cloud",
		state: "MN",
		lat: 45.593031,
		lon: -94.488363,
	},
	{
		geocode: 13460,
		city: "Bend",
		state: "OR",
		lat: 43.915118,
		lon: -121.225575,
	},
	{
		geocode: 39540,
		city: "Racine",
		state: "WI",
		lat: 42.780392,
		lon: -87.771599,
	},
	{
		geocode: 47580,
		city: "Warner Robins",
		state: "GA",
		lat: 32.492806,
		lon: -83.711084,
	},
	{
		geocode: 40980,
		city: "Saginaw",
		state: "MI",
		lat: 43.328267,
		lon: -84.05541,
	},
	{
		geocode: 39460,
		city: "Punta Gorda",
		state: "FL",
		lat: 26.906396,
		lon: -82.003657,
	},
	{
		geocode: 45460,
		city: "Terre Haute",
		state: "IN",
		lat: 39.478393,
		lon: -87.311131,
	},
	{
		geocode: 13740,
		city: "Billings",
		state: "MT",
		lat: 45.63714,
		lon: -108.832361,
	},
	{
		geocode: 39820,
		city: "Redding",
		state: "CA",
		lat: 40.760514,
		lon: -122.043556,
	},
	{
		geocode: 20100,
		city: "Dover",
		state: "DE",
		lat: 39.097088,
		lon: -75.502982,
	},
	{
		geocode: 28740,
		city: "Kingston",
		state: "NY",
		lat: 41.947212,
		lon: -74.265458,
	},
	{
		geocode: 27900,
		city: "Joplin",
		state: "MO",
		lat: 37.055546,
		lon: -94.339736,
	},
	{
		geocode: 49700,
		city: "Yuba City",
		state: "CA",
		lat: 39.160969,
		lon: -121.511833,
	},
	{
		geocode: 27180,
		city: "Jackson",
		state: "TN",
		lat: 35.753215,
		lon: -88.882692,
	},
	{
		geocode: 41100,
		city: "St. George",
		state: "UT",
		lat: 37.262531,
		lon: -113.4878,
	},
	{
		geocode: 20940,
		city: "El Centro",
		state: "CA",
		lat: 33.040814,
		lon: -115.3554,
	},
	{
		geocode: 14540,
		city: "Bowling Green",
		state: "KY",
		lat: 37.046804,
		lon: -86.397398,
	},
	{
		geocode: 10180,
		city: "Abilene",
		state: "TX",
		lat: 32.452022,
		lon: -99.718743,
	},
	{
		geocode: 34740,
		city: "Muskegon",
		state: "MI",
		lat: 43.428739,
		lon: -86.420908,
	},
	{
		geocode: 26980,
		city: "Iowa City",
		state: "IA",
		lat: 41.517806,
		lon: -91.64793,
	},
	{
		geocode: 33260,
		city: "Midland",
		state: "TX",
		lat: 32.092359,
		lon: -101.994539,
	},
	{
		geocode: 37460,
		city: "Panama City",
		state: "FL",
		lat: 30.15914,
		lon: -85.534395,
	},
	{
		geocode: 12220,
		city: "Auburn",
		state: "AL",
		lat: 32.604064,
		lon: -85.353048,
	},
	{
		geocode: 25620,
		city: "Hattiesburg",
		state: "MS",
		lat: 31.273766,
		lon: -89.300634,
	},
	{
		geocode: 20740,
		city: "Eau Claire",
		state: "WI",
		lat: 44.938672,
		lon: -91.284322,
	},
	{
		geocode: 36780,
		city: "Oshkosh",
		state: "WI",
		lat: 44.085707,
		lon: -88.668149,
	},
	{
		geocode: 15500,
		city: "Burlington",
		state: "NC",
		lat: 36.043954,
		lon: -79.400573,
	},
	{
		geocode: 17660,
		city: "Coeur d'Alene",
		state: "ID",
		lat: 47.675957,
		lon: -116.695919,
	},
	{
		geocode: 14010,
		city: "Bloomington",
		state: "IL",
		lat: 40.494559,
		lon: -88.844539,
	},
	{
		geocode: 24780,
		city: "Greenville",
		state: "NC",
		lat: 35.59249,
		lon: -77.372739,
	},
	{
		geocode: 47940,
		city: "Waterloo",
		state: "IA",
		lat: 42.536796,
		lon: -92.471608,
	},
	{
		geocode: 20700,
		city: "East Stroudsburg",
		state: "PA",
		lat: 41.05624,
		lon: -75.329065,
	},
	{
		geocode: 39380,
		city: "Pueblo",
		state: "CO",
		lat: 38.162765,
		lon: -104.484668,
	},
	{
		geocode: 48140,
		city: "Wausau",
		state: "WI",
		lat: 45.05398,
		lon: -89.757905,
	},
	{
		geocode: 13980,
		city: "Blacksburg",
		state: "VA",
		lat: 37.120119,
		lon: -80.559147,
	},
	{
		geocode: 36220,
		city: "Odessa",
		state: "TX",
		lat: 31.865301,
		lon: -102.542507,
	},
	{
		geocode: 27980,
		city: "Kahului",
		state: "HI",
		lat: 20.855931,
		lon: -156.60155,
	},
	{
		geocode: 27500,
		city: "Janesville",
		state: "WI",
		lat: 42.66988,
		lon: -89.075284,
	},
	{
		geocode: 14020,
		city: "Bloomington",
		state: "IN",
		lat: 39.241736,
		lon: -86.671754,
	},
	{
		geocode: 27100,
		city: "Jackson",
		state: "MI",
		lat: 42.248474,
		lon: -84.420868,
	},
	{
		geocode: 42680,
		city: "Sebastian",
		state: "FL",
		lat: 27.700528,
		lon: -80.574789,
	},
	{
		geocode: 44300,
		city: "State College",
		state: "PA",
		lat: 40.909128,
		lon: -77.847877,
	},
	{
		geocode: 26820,
		city: "Idaho Falls",
		state: "ID",
		lat: 43.620423,
		lon: -112.420919,
	},
	{
		geocode: 19460,
		city: "Decatur",
		state: "AL",
		lat: 34.489361,
		lon: -87.097147,
	},
	{
		geocode: 31460,
		city: "Madera",
		state: "CA",
		lat: 37.209821,
		lon: -119.749802,
	},
	{
		geocode: 16540,
		city: "Chambersburg",
		state: "PA",
		lat: 39.926772,
		lon: -77.724521,
	},
	{
		geocode: 24300,
		city: "Grand Junction",
		state: "CO",
		lat: 39.019524,
		lon: -108.460571,
	},
	{
		geocode: 21060,
		city: "Elizabethtown",
		state: "KY",
		lat: 37.73298,
		lon: -85.972171,
	},
	{
		geocode: 42140,
		city: "Santa Fe",
		state: "NM",
		lat: 35.514531,
		lon: -105.963972,
	},
	{
		geocode: 33780,
		city: "Monroe",
		state: "MI",
		lat: 41.916097,
		lon: -83.487106,
	},
	{
		geocode: 35660,
		city: "Niles",
		state: "MI",
		lat: 41.791382,
		lon: -86.742543,
	},
	{
		geocode: 47220,
		city: "Vineland",
		state: "NJ",
		lat: 39.328434,
		lon: -75.121628,
	},
	{
		geocode: 26140,
		city: "Homosassa Springs",
		state: "FL",
		lat: 28.850292,
		lon: -82.595552,
	},
	{
		geocode: 25260,
		city: "Hanford",
		state: "CA",
		lat: 36.072478,
		lon: -119.81553,
	},
	{
		geocode: 12620,
		city: "Bangor",
		state: "ME",
		lat: 45.409284,
		lon: -68.666616,
	},
	{
		geocode: 10780,
		city: "Alexandria",
		state: "LA",
		lat: 31.325348,
		lon: -92.546803,
	},
	{
		geocode: 20020,
		city: "Dothan",
		state: "AL",
		lat: 31.245528,
		lon: -85.46919,
	},
	{
		geocode: 22520,
		city: "Florence",
		state: "AL",
		lat: 34.799254,
		lon: -87.719388,
	},
	{
		geocode: 27620,
		city: "Jefferson City",
		state: "MO",
		lat: 38.636885,
		lon: -92.088876,
	},
	{
		geocode: 43580,
		city: "Sioux City",
		state: "IA",
		lat: 42.512942,
		lon: -96.438949,
	},
	{
		geocode: 10500,
		city: "Albany",
		state: "GA",
		lat: 31.644686,
		lon: -84.110718,
	},
	{
		geocode: 48660,
		city: "Wichita Falls",
		state: "TX",
		lat: 33.776144,
		lon: -98.501907,
	},
	{
		geocode: 46660,
		city: "Valdosta",
		state: "GA",
		lat: 30.827158,
		lon: -83.243555,
	},
	{
		geocode: 45500,
		city: "Texarkana",
		state: "TX",
		lat: 33.478373,
		lon: -94.207162,
	},
	{
		geocode: 30860,
		city: "Logan",
		state: "UT",
		lat: 41.88902,
		lon: -111.787438,
	},
	{
		geocode: 22380,
		city: "Flagstaff",
		state: "AZ",
		lat: 35.829692,
		lon: -111.773728,
	},
	{
		geocode: 40580,
		city: "Rocky Mount",
		state: "NC",
		lat: 35.941045,
		lon: -77.798549,
	},
	{
		geocode: 30140,
		city: "Lebanon",
		state: "PA",
		lat: 40.371448,
		lon: -76.464819,
	},
	{
		geocode: 19140,
		city: "Dalton",
		state: "GA",
		lat: 34.796956,
		lon: -84.856279,
	},
	{
		geocode: 34100,
		city: "Morristown",
		state: "TN",
		lat: 36.168802,
		lon: -83.422614,
	},
	{
		geocode: 49020,
		city: "Winchester",
		state: "VA",
		lat: 39.272974,
		lon: -78.471828,
	},
	{
		geocode: 34060,
		city: "Morgantown",
		state: "WV",
		lat: 39.525155,
		lon: -79.801912,
	},
	{
		geocode: 29100,
		city: "La Crosse",
		state: "WI",
		lat: 43.783246,
		lon: -91.318743,
	},
	{
		geocode: 48540,
		city: "Wheeling",
		state: "WV",
		lat: 39.977687,
		lon: -80.854826,
	},
	{
		geocode: 39660,
		city: "Rapid City",
		state: "SD",
		lat: 44.314673,
		lon: -102.785719,
	},
	{
		geocode: 34900,
		city: "Napa",
		state: "CA",
		lat: 38.5071,
		lon: -122.325904,
	},
	{
		geocode: 44940,
		city: "Sumter",
		state: "SC",
		lat: 33.792654,
		lon: -80.294055,
	},
	{
		geocode: 44220,
		city: "Springfield",
		state: "OH",
		lat: 39.917032,
		lon: -83.783676,
	},
	{
		geocode: 25500,
		city: "Harrisonburg",
		state: "VA",
		lat: 38.507585,
		lon: -78.885321,
	},
	{
		geocode: 43300,
		city: "Sherman",
		state: "TX",
		lat: 33.624524,
		lon: -96.675693,
	},
	{
		geocode: 12980,
		city: "Battle Creek",
		state: "MI",
		lat: 42.24299,
		lon: -85.012385,
	},
	{
		geocode: 27860,
		city: "Jonesboro",
		state: "AR",
		lat: 35.696978,
		lon: -90.650175,
	},
	{
		geocode: 31740,
		city: "Manhattan",
		state: "KS",
		lat: 39.268424,
		lon: -96.562242,
	},
	{
		geocode: 13900,
		city: "Bismarck",
		state: "ND",
		lat: 46.887031,
		lon: -100.979592,
	},
	{
		geocode: 27780,
		city: "Johnstown",
		state: "PA",
		lat: 40.510227,
		lon: -78.710477,
	},
	{
		geocode: 16060,
		city: "Carbondale",
		state: "IL",
		lat: 37.689742,
		lon: -89.105149,
	},
	{
		geocode: 25220,
		city: "Hammond",
		state: "LA",
		lat: 30.621348,
		lon: -90.40649,
	},
	{
		geocode: 45540,
		city: "The Villages",
		state: "FL",
		lat: 28.715444,
		lon: -82.069952,
	},
	{
		geocode: 34580,
		city: "Mount Vernon",
		state: "WA",
		lat: 48.493292,
		lon: -121.81577,
	},
	{
		geocode: 38340,
		city: "Pittsfield",
		state: "MA",
		lat: 42.371493,
		lon: -73.217928,
	},
	{
		geocode: 10540,
		city: "Albany",
		state: "OR",
		lat: 44.488898,
		lon: -122.537208,
	},
	{
		geocode: 24020,
		city: "Glens Falls",
		state: "NY",
		lat: 43.44045,
		lon: -73.636757,
	},
	{
		geocode: 30020,
		city: "Lawton",
		state: "OK",
		lat: 34.529113,
		lon: -98.430902,
	},
	{
		geocode: 17420,
		city: "Cleveland",
		state: "TN",
		lat: 35.138117,
		lon: -84.655079,
	},
	{
		geocode: 43420,
		city: "Sierra Vista",
		state: "AZ",
		lat: 31.840129,
		lon: -109.775163,
	},
	{
		geocode: 44420,
		city: "Staunton",
		state: "VA",
		lat: 38.160958,
		lon: -79.127632,
	},
	{
		geocode: 11180,
		city: "Ames",
		state: "IA",
		lat: 42.036128,
		lon: -93.698008,
	},
	{
		geocode: 31900,
		city: "Mansfield",
		state: "OH",
		lat: 40.774159,
		lon: -82.542781,
	},
	{
		geocode: 41660,
		city: "San Angelo",
		state: "TX",
		lat: 31.479839,
		lon: -100.77459,
	},
	{
		geocode: 11020,
		city: "Altoona",
		state: "PA",
		lat: 40.498683,
		lon: -78.309597,
	},
	{
		geocode: 35100,
		city: "New Bern",
		state: "NC",
		lat: 35.116829,
		lon: -77.08132,
	},
	{
		geocode: 48300,
		city: "Wenatchee",
		state: "WA",
		lat: 47.76611,
		lon: -120.270197,
	},
	{
		geocode: 22140,
		city: "Farmington",
		state: "NM",
		lat: 36.511624,
		lon: -108.324578,
	},
	{
		geocode: 36980,
		city: "Owensboro",
		state: "KY",
		lat: 37.693723,
		lon: -87.077058,
	},
	{
		geocode: 41140,
		city: "St. Joseph",
		state: "MO",
		lat: 39.834843,
		lon: -94.783754,
	},
	{
		geocode: 29940,
		city: "Lawrence",
		state: "KS",
		lat: 38.896417,
		lon: -95.290947,
	},
	{
		geocode: 43100,
		city: "Sheboygan",
		state: "WI",
		lat: 43.741232,
		lon: -87.731516,
	},
	{
		geocode: 33540,
		city: "Missoula",
		state: "MT",
		lat: 47.027263,
		lon: -113.892691,
	},
	{
		geocode: 24140,
		city: "Goldsboro",
		state: "NC",
		lat: 35.35419,
		lon: -78.00867,
	},
	{
		geocode: 48260,
		city: "Weirton",
		state: "WV",
		lat: 40.388914,
		lon: -80.701966,
	},
	{
		geocode: 48060,
		city: "Watertown",
		state: "NY",
		lat: 43.996389,
		lon: -76.052968,
	},
	{
		geocode: 11500,
		city: "Anniston",
		state: "AL",
		lat: 33.770516,
		lon: -85.827909,
	},
	{
		geocode: 13220,
		city: "Beckley",
		state: "WV",
		lat: 37.905325,
		lon: -81.164436,
	},
	{
		geocode: 46300,
		city: "Twin Falls",
		state: "ID",
		lat: 42.440397,
		lon: -114.568729,
	},
	{
		geocode: 48700,
		city: "Williamsport",
		state: "PA",
		lat: 41.343882,
		lon: -77.055262,
	},
	{
		geocode: 15680,
		city: "California",
		state: "MD",
		lat: 38.223077,
		lon: -76.534487,
	},
	{
		geocode: 15260,
		city: "Brunswick",
		state: "GA",
		lat: 31.301739,
		lon: -81.588909,
	},
	{
		geocode: 33140,
		city: "Michigan City",
		state: "IN",
		lat: 41.549013,
		lon: -86.744739,
	},
	{
		geocode: 34620,
		city: "Muncie",
		state: "IN",
		lat: 40.227543,
		lon: -85.399262,
	},
	{
		geocode: 30340,
		city: "Lewiston",
		state: "ME",
		lat: 44.167681,
		lon: -70.207435,
	},
	{
		geocode: 31020,
		city: "Longview",
		state: "WA",
		lat: 46.196785,
		lon: -122.67846,
	},
	{
		geocode: 28100,
		city: "Kankakee",
		state: "IL",
		lat: 41.13951,
		lon: -87.861117,
	},
	{
		geocode: 27060,
		city: "Ithaca",
		state: "NY",
		lat: 42.453006,
		lon: -76.473483,
	},
	{
		geocode: 24220,
		city: "Grand Forks",
		state: "ND",
		lat: 47.835929,
		lon: -96.842681,
	},
	{
		geocode: 22540,
		city: "Fond du Lac",
		state: "WI",
		lat: 43.754722,
		lon: -88.493284,
	},
	{
		geocode: 19500,
		city: "Decatur",
		state: "IL",
		lat: 39.860237,
		lon: -88.961529,
	},
	{
		geocode: 13020,
		city: "Bay City",
		state: "MI",
		lat: 43.699711,
		lon: -83.978701,
	},
	{
		geocode: 23900,
		city: "Gettysburg",
		state: "PA",
		lat: 39.869471,
		lon: -77.21773,
	},
	{
		geocode: 31860,
		city: "Mankato",
		state: "MN",
		lat: 44.15481,
		lon: -94.147801,
	},
	{
		geocode: 23460,
		city: "Gadsden",
		state: "AL",
		lat: 34.047641,
		lon: -86.034263,
	},
	{
		geocode: 30620,
		city: "Lima",
		state: "OH",
		lat: 40.771627,
		lon: -84.106103,
	},
	{
		geocode: 42700,
		city: "Sebring",
		state: "FL",
		lat: 27.34108,
		lon: -81.342353,
	},
	{
		geocode: 16940,
		city: "Cheyenne",
		state: "WY",
		lat: 41.29283,
		lon: -104.660395,
	},
	{
		geocode: 26300,
		city: "Hot Springs",
		state: "AR",
		lat: 34.578852,
		lon: -93.146932,
	},
	{
		geocode: 20220,
		city: "Dubuque",
		state: "IA",
		lat: 42.463481,
		lon: -90.878771,
	},
	{
		geocode: 40660,
		city: "Rome",
		state: "GA",
		lat: 34.263692,
		lon: -85.213685,
	},
	{
		geocode: 47020,
		city: "Victoria",
		state: "TX",
		lat: 28.731455,
		lon: -97.196993,
	},
	{
		geocode: 16020,
		city: "Cape Girardeau",
		state: "MO",
		lat: 37.325936,
		lon: -89.762639,
	},
	{
		geocode: 21820,
		city: "Fairbanks",
		state: "AK",
		lat: 64.676044,
		lon: -146.548159,
	},
	{
		geocode: 36140,
		city: "Ocean City",
		state: "NJ",
		lat: 39.085841,
		lon: -74.846354,
	},
	{
		geocode: 18700,
		city: "Corvallis",
		state: "OR",
		lat: 44.493882,
		lon: -123.424664,
	},
	{
		geocode: 19060,
		city: "Cumberland",
		state: "MD",
		lat: 39.532185,
		lon: -78.807082,
	},
	{
		geocode: 38540,
		city: "Pocatello",
		state: "ID",
		lat: 42.689761,
		lon: -112.57753,
	},
	{
		geocode: 37620,
		city: "Parkersburg",
		state: "WV",
		lat: 39.141531,
		lon: -81.460402,
	},
	{
		geocode: 24420,
		city: "Grants Pass",
		state: "OR",
		lat: 42.386983,
		lon: -123.57162,
	},
	{
		geocode: 38220,
		city: "Pine Bluff",
		state: "AR",
		lat: 34.081204,
		lon: -91.945765,
	},
	{
		geocode: 24500,
		city: "Great Falls",
		state: "MT",
		lat: 47.316575,
		lon: -111.350262,
	},
	{
		geocode: 21300,
		city: "Elmira",
		state: "NY",
		lat: 42.148697,
		lon: -76.752471,
	},
	{
		geocode: 29020,
		city: "Kokomo",
		state: "IN",
		lat: 40.483537,
		lon: -86.114118,
	},
	{
		geocode: 33220,
		city: "Midland",
		state: "MI",
		lat: 43.648203,
		lon: -84.379414,
	},
	{
		geocode: 14100,
		city: "Bloomsburg",
		state: "PA",
		lat: 41.040404,
		lon: -76.461654,
	},
	{
		geocode: 18020,
		city: "Columbus",
		state: "IN",
		lat: 39.205843,
		lon: -85.897999,
	},
	{
		geocode: 25980,
		city: "Hinesville",
		state: "GA",
		lat: 31.778788,
		lon: -81.57648,
	},
	{
		geocode: 16220,
		city: "Casper",
		state: "WY",
		lat: 42.977645,
		lon: -106.768219,
	},
	{
		geocode: 24260,
		city: "Grand Island",
		state: "NE",
		lat: 41.083926,
		lon: -98.374218,
	},
	{
		geocode: 19180,
		city: "Danville",
		state: "IL",
		lat: 40.186754,
		lon: -87.726778,
	},
	{
		geocode: 30300,
		city: "Lewiston",
		state: "ID",
		lat: 46.251696,
		lon: -116.925017,
	},
	{
		geocode: 21420,
		city: "Enid",
		state: "OK",
		lat: 36.378132,
		lon: -97.788741,
	},
	{
		geocode: 47460,
		city: "Walla Walla",
		state: "WA",
		lat: 46.254606,
		lon: -118.48037,
	},
	{
		geocode: 16180,
		city: "Carson City",
		state: "NV",
		lat: 39.15306,
		lon: -119.747368,
	},
];
