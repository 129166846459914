import React from "react";
import { useAuth } from "../../firebase";
import Header from "./../Header/Header";
import PostSelection from "./PostSelection";
import "./PostSelection.css";

function PostSelectionPage() {
  const currentUser = useAuth();
  return (
    <div className="post_selection__container">
      <Header />
      {!currentUser && <div>hello</div>}
      {currentUser && (
        <div className="post_selection">
          <PostSelection />
        </div>
      )}
    </div>
  );
}

export default PostSelectionPage;
