import React, { useState, useEffect, useRef } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import {
  getAuth,
  confirmPasswordReset,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { TextField, Button } from "@mui/material/";
import Header from "./../Header/Header";
import "./SignUp.css";

function ResetPassword() {
  const [searchParams] = useSearchParams();
  const auth = getAuth();
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState("");
  const [pwResetCode, setPwResetCode] = useState("");
  const [email, setEmail] = useState("");

  console.log("formatted email", email);

  const passwordRef = useRef("");

  useEffect(() => {
    let unmounted = false;
    let resetCode;
    let emailAddress;
    if (!unmounted) {
      resetCode = searchParams.get("oobCode");
      if (resetCode) {
        const resetCode2 = resetCode.replace("/", "");
        setPwResetCode(resetCode2);
      }
      emailAddress = searchParams.get("email");

      if (emailAddress) {
        const emailAddress2 = emailAddress.replace("/", "");
        const emailAddress3 = emailAddress2.replace(" ", "+");
        setEmail(emailAddress3);
      }
    }
    return () => {
      unmounted = true;
    };
  }, [searchParams, pwResetCode]);

  const handleResetPassword = () => {
    confirmPasswordReset(auth, pwResetCode, newPassword)
      .then(() => {
        if (email !== "") {
          signInWithEmailAndPassword(auth, email, newPassword)
            .then((result) => {
              navigate("/");
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          navigate("/login");
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  return (
    <div className="signup__container">
      <Header />
      <div className="signup">
        <h2 className="welcome-title">Reset Password</h2>
        <TextField
          required
          label="New Password"
          type="password"
          margin="normal"
          value={newPassword}
          ref={passwordRef}
          onChange={(e) => {
            setNewPassword(e.target.value);
          }}
        />

        <Button
          onClick={handleResetPassword}
          variant="contained"
          style={{ marginTop: 16, marginBottom: 8 }}
          size="large"
          disabled={!newPassword}
        >
          Reset Password
        </Button>
      </div>
    </div>
  );
}

export default ResetPassword;
