import React from "react";
import CreatePost from "./CreatePost";
import { subcategories } from "../../lookups/categories";

function PostHousing() {
  return (
    <CreatePost
      category="home"
      categoryLabel="Home or Apartment"
      category1Label="Type"
      category1Options={subcategories.home}
      category2Label="NA"
      postTitleLabel="Title"
      postSubTitleLabel="Headline"
      postDescriptionLabel="Descrition"
      postWebsiteUrlLabel="Listing Link"
      postWebsiteUrlLabel2=""
      postAmountTypes={[
        { value: "rent", label: "month" },
        { value: "buy", label: "price" },
      ]}
    />
  );
}

export default PostHousing;
