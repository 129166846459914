import React from "react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import PostSelection from "../PostSelection/PostSelection";
import "./PostDrawer.css";

function PostDrawer(props) {
  const { open = false, onClose = () => {} } = props;

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      onOpen={() => {}}
      className="post_drawer_container"
      BackdropProps={{ invisible: true }}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          backgroundImage: "none",
          maxHeight: "85vh",
        },
      }}
    >
      <div className="post_drawer_container">
        <div className="post_drawer_contents">
          <PostSelection />
        </div>
      </div>
    </SwipeableDrawer>
  );
}

export default PostDrawer;
