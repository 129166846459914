import React from "react";
import { Link } from "react-router-dom";
import Header from "./../Header/Header";
import LogIn from "../SignUp/LogIn";
import "./SignUp.css";

function LogInPage() {
  return (
    <div className="signup__container">
      <Header />
      <LogIn />
      <div>
        <p>
          Don't have an account yet?{" "}
          <Link className="switch" to={{ pathname: "/signup" }}>
            Sign up{" "}
          </Link>
        </p>
      </div>
    </div>
  );
}

export default LogInPage;
