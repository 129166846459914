import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { storage, db } from "../firebase";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

const CreatePostHook = (file, attrs) => {
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(null);
  const [url, setUrl] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const storageRef = ref(storage, file.name);

    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percentage =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(percentage);
      },
      (error) => {
        setError(error);
      },
      async () => {
        const url = await getDownloadURL(uploadTask.snapshot.ref);

        const {
          postUserId,
          category,
          category1,
          category2,
          postTitle,
          postSubTitle,
          postDescription,
          postAmountType,
          postAmount,
          postWebsiteUrl,
          longitude,
          latitude,
          city,
          state,
          geocode,
        } = attrs;

        setUrl(url);
        addDoc(collection(db, "videos"), {
          postUserId,
          createdAt: serverTimestamp(),
          category,
          category1,
          category2,
          postTitle,
          postSubTitle,
          postDescription,
          postAmountType,
          postAmount,
          postWebsiteUrl,
          longitude,
          latitude,
          city,
          state,
          geocode,
          url,
          likes: 0,
        });
        navigate("/");
      }
    );
  }, [attrs, file, navigate]);

  return { progress, url, error };
};

export default CreatePostHook;
