import React, { useState, useEffect } from "react";
import { useAuth } from "../../firebase";
import "./VideoSidebar.css";
import Alert from "@mui/material/Alert";
import Slide from "@mui/material/Slide";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteTwoToneIcon from "@mui/icons-material/FavoriteTwoTone";
import AccountCircleTwoToneIcon from "@mui/icons-material/AccountCircleTwoTone";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import IosShareIcon from "@mui/icons-material/IosShare";
import ReplyIcon from "@mui/icons-material/Reply";
import ShareDrawer from "../ShareDrawer/ShareDrawer";
import { likeVideo, unlikeVideo } from "../../hooks/LikeHook";

function VideoSidebar(props) {
  const { id, data, userLiked, handlePostOpen, handleOpenSignUp } = props;
  const [liked, setLiked] = useState();
  const [showLikedAlert, setShowLikedAlert] = useState(false);
  const [openDrawerShare, setOpenDrawerShare] = useState(false);

  const [websiteUrl, setWebsiteUrl] = useState("");

  const handleOpenShare = () => setOpenDrawerShare(true);
  const handleCloseShare = () => setOpenDrawerShare(false);
  const currentUser = useAuth();

  useEffect(() => {
    setWebsiteUrl(data.postWebsiteUrl.replace(/^https?:\/\//i, ""));
  }, [data.postWebsiteUrl]);

  useEffect(() => {
    setLiked(userLiked);
  }, [userLiked]);

  const handleOpenDrawerShare = () => {
    if (navigator.share) {
      navigator
        .share({
          text: `Check out this ${data.category} on Bundle! - ${data.postTitle}`,
          //text: data.postTitle,
          url: `https://bundlefind.com?post_id=${id}`,
        })
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing", error));
    } else {
      handleOpenShare();
    }
  };

  const handleLiked = () => {
    const likerData = data;

    if (currentUser) {
      likerData.userId = currentUser.uid;
      if (!liked) {
        setShowLikedAlert(true);
        setTimeout(() => setShowLikedAlert(false), 2000);
      }
    }
    !currentUser && handleOpenSignUp();

    likerData.videoId = id;

    if (!liked) {
      likeVideo(id, likerData);
    }
    if (liked) {
      unlikeVideo(id, likerData);
    }

    setLiked(!liked);
  };

  return (
    <div className="videoSidebar">
      <ShareDrawer onClose={handleCloseShare} open={openDrawerShare} id={id} />
      {currentUser ? (
        <div className="videoSidebar__button" onClick={handlePostOpen}>
          <AddBoxTwoToneIcon className="post-icon" fontSize="large" />
          <p>Post</p>
        </div>
      ) : (
        <div
          className="videoSidebar__button"
          onClick={() => handleOpenSignUp("post")}
        >
          <AddBoxTwoToneIcon fontSize="large" />
          <p>Post</p>
        </div>
      )}
      <div className="videoSidebar__button" onClick={handleOpenSignUp}>
        <AccountCircleTwoToneIcon fontSize="large" />
        <p>{currentUser ? "Profile" : "Sign up"}</p>
      </div>
      <div className="videoSidebar__button" onClick={handleOpenDrawerShare}>
        <ReplyIcon fontSize="large" style={{ transform: "rotateY(180deg)" }} />
        <p>Share</p>
      </div>
      {data.postWebsiteUrl && (
        <a
          className="icon_link"
          target="_blank"
          rel="noreferrer"
          href={`//${websiteUrl}`}
        >
          <div className="videoSidebar__button">
            <IosShareIcon
              fontSize="large"
              style={{ transform: "rotate(90deg)" }}
            />
            <p>Details</p>
          </div>
        </a>
      )}

      <div className="videoSidebar__button" onClick={handleLiked}>
        {liked ? (
          <FavoriteIcon className="liked-icon" fontSize="large" />
        ) : (
          <FavoriteTwoToneIcon fontSize="large" />
        )}
        <p>{data.likes}</p>
      </div>
      <Slide direction="right" in={showLikedAlert}>
        <Alert id="like-alert" severity="success">
          Success: Added to Favorites!
        </Alert>
      </Slide>
    </div>
  );
}

export default VideoSidebar;
