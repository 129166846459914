import React from "react";
import { Link } from "react-router-dom";
import Header from "./../Header/Header";
import SignUp from "../SignUp/SignUp";
import "./SignUp.css";

function SignUpPage() {
  return (
    <div className="signup__container">
      <Header />
      <SignUp />
      <div>
        <p>
          Already have an account?{" "}
          <Link className="switch" to={{ pathname: "/login" }}>
            Log in{" "}
          </Link>
        </p>
      </div>
    </div>
  );
}

export default SignUpPage;
