import React from "react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import LocationSelection from "./LocationSelection";
import "./LocationDrawer.css";

function LocationDrawer(props) {
  const { open = false, onClose = () => {}, location, setLocation } = props;

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      onOpen={() => {}}
      className="location_drawer_container"
      BackdropProps={{ invisible: true }}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          backgroundImage: "none",
          maxHeight: "85vh",
          minHeight: "60vh",
          height: "100%",
        },
      }}
    >
      <div className="location_drawer_container">
        <div className="location_drawer_contents">
          <h1>Choose a City</h1>
          <LocationSelection
            location={location}
            setLocation={setLocation}
            onClose={onClose}
          />
        </div>
      </div>
    </SwipeableDrawer>
  );
}

export default LocationDrawer;
