import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  getAuth,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { TextField, Button } from "@mui/material/";
import GoogleIcon from "./btn_google.svg";
import { useAuth } from "../../firebase";
import "./SignUp.css";

function SignUp(props) {
  const { trigger } = props;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const emailRef = useRef("");
  const passwordRef = useRef("");
  const navigate = useNavigate();
  const currentUser = useAuth();
  const auth = getAuth();

  const provider = new GoogleAuthProvider();

  const signInWithGoogle = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        if (trigger === "post") {
          navigate("/post");
        } else {
          navigate("/");
        }
        console.log(result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSignup = () => {
    createUserWithEmailAndPassword(auth, email, password)
      .then((result) => {
        if (trigger === "post") {
          navigate("/post");
        } else {
          navigate("/");
        }
        console.log(result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      {!currentUser && (
        <div className="signup">
          <h2 className="welcome-title">Welcome to Bundle</h2>
          <div className="google-btn" onClick={signInWithGoogle}>
            <img alt="Sign up with Google" src={GoogleIcon} />
            <p>Sign up with Google</p>
          </div>

          <div className="divider">
            <div className="line" />
            <p style={{ lineHeight: 1.2 }}>or</p>
            <div className="line" />
          </div>
          <TextField
            inputProps={{ spellCheck: "false" }}
            required
            label="Email"
            type="email"
            margin="normal"
            value={email}
            ref={emailRef}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <TextField
            required
            label="Password"
            type="password"
            margin="normal"
            value={password}
            ref={passwordRef}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          <Button
            onClick={handleSignup}
            variant="contained"
            style={{ marginTop: 16, marginBottom: 8 }}
            size="large"
            disabled={!email || !password}
          >
            Sign Up
          </Button>
        </div>
      )}
      {currentUser && (
        <div className="signup">
          <div>Currently logged in as: {currentUser?.email} </div>

          <h1>
            <img className="user-img" alt="" src={currentUser?.photoURL} />
            {currentUser?.displayName}
          </h1>
        </div>
      )}
    </div>
  );
}

export default SignUp;
