import React, { useState } from "react";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import PostSteps from "./PostSteps";
import { category_main } from "../../lookups/categories";
import "./PostSelection.css";

const style = {
  width: "100%",
};

const itemStyle = {
  flexGrow: 0,
  marginRight: 2,
};

const arrowStyle = {
  position: "absolute",
  right: 5,
};

function PostSelection() {
  const [showTips, setShowTips] = useState(false);

  const handleShowTips = () => {
    setShowTips(!showTips);
  };

  return (
    <div>
      {showTips ? (
        <PostSteps />
      ) : (
        <div>
          <h1 className="post_selection_title">Post a Video</h1>
          <List sx={style} component="nav">
            {category_main.map(({ value, label }) => (
              <Link
                key={value}
                className="post_selection_link"
                to={`/post/${value}`}
              >
                <ListItem button divider key={value}>
                  <ListItemText sx={itemStyle} primary={label} />
                  <ArrowForwardIosIcon sx={arrowStyle} />
                </ListItem>
              </Link>
            ))}
          </List>
        </div>
      )}
      <div className="tips-button">
        <Button onClick={handleShowTips} variant="outlined" margin="normal">
          {showTips ? "Create a Post" : "Show Posting Tips"}
        </Button>
      </div>
    </div>
  );
}

export default PostSelection;
