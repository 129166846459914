import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./Home/Home";
import UserSettingsPage from "./UserSettings/UserSettingsPage";
import PostSelectionPage from "./PostSelection/PostSelectionPage";
import PostJob from "./CreatePost/PostJob";
import PostRestaurant from "./CreatePost/PostRestaurant";
import PostPro from "./CreatePost/PostPro";
import PostHousing from "./CreatePost/PostHousing";
import PostActivity from "./CreatePost/PostActivity";
import SignUpPage from "./SignUp/SignUpPage";
import LogInPage from "./SignUp/LogInPage";
import ResetPassword from "./SignUp/ResetPassword";

const Main = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/signup" element={<SignUpPage />} />
      <Route path="/login" element={<LogInPage />} />
            <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/settings" element={<UserSettingsPage />} />
      <Route path="/post" element={<PostSelectionPage />} />
      <Route path="/post/job" element={<PostJob />} />
      <Route path="/post/restaurant" element={<PostRestaurant />} />
      <Route path="/post/pro" element={<PostPro />} />
      <Route path="/post/home" element={<PostHousing />} />
      <Route path="/post/activity" element={<PostActivity />} />
    </Routes>
  );
};

export default Main;
