import React, { useState } from "react";
import { Button } from "@mui/material/";
import ProgressBar from "./ProgressBar";

const UploadForm = (props) => {
	const [file, setFile] = useState(null);
	const [error, setError] = useState(null);
	const { attrs } = props;

	const types = ["video/mp4", "video/x-m4v", "video/quicktime", "video/*"];

	const handleChange = (e) => {
		let selected = e.target.files[0];

		if (selected && types.includes(selected.type)) {
			setFile(selected);
			setError("");
		} else {
			setFile(null);
			setError("Please select a video file (MP4 or MOV)");
		}
	};

	return (
		<form>
			<Button
				variant="contained"
				style={{ marginTop: 16, marginBottom: 8, width: "100%" }}
				size="large"
				component="label"
			>
				Add Video & Create Post
				<input type="file" hidden onChange={handleChange} />
			</Button>
			<div className="output">
				{error && <div className="error">{error}</div>}
				{file && <div>{file.name}</div>}
				{file && (
					<ProgressBar file={file} setFile={setFile} attrs={attrs} />
				)}
			</div>
		</form>
	);
};

export default UploadForm;
