import React from "react";
import CreatePost from "./CreatePost";
import { subcategories } from "../../lookups/categories";

function PostRestaurant() {
  return (
    <CreatePost
      category="restaurant"
      categoryLabel="Restaurant"
      category1Label="Cuisine"
      category1Options={subcategories.restaurant}
      category2Label="NA"
      postTitleLabel="Restaurant Name"
      postSubTitleLabel="Headline"
      postDescriptionLabel="Description"
      postWebsiteUrlLabel="Website URL"
      postWebsiteUrlLabel2=""
      postAmountTypes={[{ value: "", label: "" }]}
    />
  );
}

export default PostRestaurant;
