import React, { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import Button from "@mui/material/Button";
import Seo from "../Seo/Seo";
import Video from "../Video/Video";
import FilterDrawer from "../FilterDrawer/FilterDrawer";
import LocationDrawer from "../LocationDrawer/LocationDrawer";
import PostDrawer from "../PostDrawer/PostDrawer";
import SignUpDrawer from "../SignUpDrawer/SignUpDrawer";
import { getVideos } from "../../hooks/GetVideosHook";
import { getLikes } from "../../hooks/GetLikesHook";
import { getPosts } from "../../hooks/GetPostsHook";
import { auth } from "../../firebase";
import { category_main } from "../../lookups/categories";
import "./Home.css";
import SyncLoader from "react-spinners/SyncLoader";

function Home() {
  const { currentUser } = auth;
  const [searchParams] = useSearchParams();
  const videosArrayRef = useRef(null);
  const [shareId, setShareId] = useState("");
  const [categoryLabels, setCategoryLabels] = useState({});
  const [subCategoryArray, setSubCategoryArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [shareVideo, setShareVideo] = useState([]);
  const [activeVideos, setActiveVideos] = useState([]);
  const [videos, setVideos] = useState([]);
  const [postVideos, setPostVideos] = useState([]);
  const [likeVideos, setLikeVideos] = useState([]);
  const [viewingPosts, setViewingPosts] = useState(false);
  const [viewingLikes, setViewingLikes] = useState(false);
  const [showAppStoreBar, setShowAppStoreBar] = useState(true);

  const [location, setLocation] = useState({
    geocode: null,
    city: "",
    state: "",
    latitude: null,
    longitude: null,
  });
  const [signUpButtonTriggered, setSignUpButtonTriggered] = useState("");

  const [filterValues, setFilterValues] = useState({
    category: "",
    category1: "",
  });

  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const [openLocationDrawer, setOpenLocationDrawer] = useState(false);
  const [openDrawerPost, setOpenDrawerPost] = useState(false);
  const [openDrawerSignUp, setOpenDrawerSignUp] = useState(false);

  const activeVideosCount = activeVideos.length;

  const handleFilterOpen = () => setOpenFilterDrawer(true);
  const handleFilterClose = () => setOpenFilterDrawer(false);

  const handleLocationOpen = () => setOpenLocationDrawer(true);
  const handleLocationClose = () => setOpenLocationDrawer(false);

  const handlePostOpen = () => setOpenDrawerPost(true);
  const handlePostClose = () => setOpenDrawerPost(false);

  const handleOpenSignUp = (trigger) => {
    setOpenDrawerSignUp(true);
    if (trigger !== "") {
      setSignUpButtonTriggered(trigger);
    }
  };
  const handleCloseSignUp = () => setOpenDrawerSignUp(false);
  const handleClearFilters = () => {
    setFilterValues({
      ...filterValues,
      category: "",
      category1: "",
    });
    setLocation({
      geocode: null,
      city: "",
      state: "",
      latitude: null,
      longitude: null,
    });
    setViewingPosts(false);
    setViewingLikes(false);
  };
  const noVideos = !loading && !activeVideos.length;

  useEffect(() => {
    if (viewingLikes) {
      setActiveVideos(likeVideos);
    } else if (viewingPosts) {
      setActiveVideos(postVideos);
    } else setActiveVideos(videos);
  }, [videos, likeVideos, postVideos, viewingLikes, viewingPosts]);

  useEffect(() => {
    getVideos(
      setVideos,
      filterValues.category,
      filterValues.category1,
      shareId,
      setShareVideo,
      setLoading,
      location.geocode
    );
  }, [filterValues, shareId, location]);

  useEffect(() => {
    videosArrayRef.current.scrollTo(0, 0);
  }, [activeVideosCount]);

  useEffect(() => {
    getLikes(
      currentUser,
      setLikeVideos,
      filterValues.category,
      filterValues.category1,
      setLoading
    );
    getPosts(
      currentUser,
      setPostVideos,
      filterValues.category,
      filterValues.category1,
      setLoading
    );
    videosArrayRef.current.scrollTo(0, 0);
  }, [filterValues, currentUser]);

  useEffect(() => {
    let unmounted = false;
    let shareParamId;
    if (!unmounted) {
      shareParamId = searchParams.get("post_id");
      if (shareParamId) {
        const shareParamId2 = shareParamId.replace("/", "");
        setShareId(shareParamId2);
      }
    }
    return () => {
      unmounted = true;
    };
  }, [searchParams]);

  useEffect(() => {
    const createLabels = () => {
      const labels = {};
      category_main.forEach(function (category) {
        const value = category.value;
        const label = category.label;
        labels[value] = label;
      });
      setCategoryLabels(labels);
    };
    createLabels();
  }, []);

  return (
    <div className="home">
      <Seo noZoom />
      <FilterDrawer
        onClose={handleFilterClose}
        open={openFilterDrawer}
        setFilterValues={setFilterValues}
        filterValues={filterValues}
        subCategoryArray={subCategoryArray}
        setSubCategoryArray={setSubCategoryArray}
        likeVideos={likeVideos}
      />
      <LocationDrawer
        onClose={handleLocationClose}
        open={openLocationDrawer}
        location={location}
        setLocation={setLocation}
      />
      <PostDrawer onClose={handlePostClose} open={openDrawerPost} />
      <SignUpDrawer
        onClose={handleCloseSignUp}
        open={openDrawerSignUp}
        currentUser={currentUser}
        trigger={signUpButtonTriggered}
        likeVideos={likeVideos}
        postVideos={postVideos}
        setLikeVideos={setLikeVideos}
        setPostVideos={setPostVideos}
        setViewingLikes={setViewingLikes}
        setViewingPosts={setViewingPosts}
        filterValues={filterValues}
        setFilterValues={setFilterValues}
      />
      <div className="home__videos" ref={videosArrayRef}>
        {showAppStoreBar && (
          <div className="download-app">
            <div className="download-container">
              <h3 className="download-app-title">
                Bundle is better on the app
              </h3>
              <p className="download-app-description">
                Search by video for jobs, restaurants, local pros, and more.
              </p>
              <div className="download-app-buttons">
                <a
                  onClick={() => {
                    setShowAppStoreBar(false);
                  }}
                  className="download-app-no"
                >
                  Not now
                </a>
                <a
                  className="download-app-yes"
                  target="_blank"
                  rel="noreferrer"
                  href="https://apps.apple.com/us/app/bundle-find/id1637469294"
                >
                  Switch to app
                </a>
              </div>
            </div>
          </div>
        )}
        {noVideos && (
          <div className="spinner_container">
            <h4 className="spinner-p">No Results in Your Area</h4>
            {currentUser ? (
              <div className="no-video-create-post" onClick={handlePostOpen}>
                <Button variant="outlined">Create Post</Button>
              </div>
            ) : (
              <div
                className="no-video-create-post"
                onClick={() => handleOpenSignUp("post")}
              >
                <Button variant="outlined">Create Post</Button>
              </div>
            )}

            <p className="spinner-p">-or-</p>
            <div className="no-video-create-post" onClick={handleClearFilters}>
              <Button variant="contained">Clear Filters</Button>
            </div>
          </div>
        )}
        {loading && (
          <div className="spinner_container">
            <SyncLoader color="#00F48E" className="spinner" />
          </div>
        )}
        {shareVideo.length > 0 && (
          <Video
            id={shareVideo[0].id}
            data={shareVideo[0].data}
            userLiked={
              likeVideos.filter((shared) => shared.id === shareVideo[0].id)
                .length > 0
                ? true
                : false
            }
            category={categoryLabels[shareVideo[0].data.category]}
            handleFilterOpen={handleFilterOpen}
            handleLocationOpen={handleLocationOpen}
            handlePostOpen={handlePostOpen}
            handleOpenSignUp={handleOpenSignUp}
            setLikeVideos={setLikeVideos}
            setPostVideos={setPostVideos}
            viewingLikes={viewingLikes}
            viewingPosts={viewingPosts}
            setViewingLikes={setViewingLikes}
            setViewingPosts={setViewingPosts}
            setFilterValues={setFilterValues}
            filterValues={filterValues}
            subCategoryArray={subCategoryArray}
            setSubCategoryArray={setSubCategoryArray}
          />
        )}
        {activeVideos.map(({ id, data }) => (
          <Video
            key={id}
            id={id}
            data={data}
            userLiked={
              likeVideos.filter((likes) => likes.id === id).length > 0
                ? true
                : false
            }
            category={categoryLabels[data.category]}
            handleFilterOpen={handleFilterOpen}
            handleLocationOpen={handleLocationOpen}
            handlePostOpen={handlePostOpen}
            handleOpenSignUp={handleOpenSignUp}
            setLikeVideos={setLikeVideos}
            setPostVideos={setPostVideos}
            viewingLikes={viewingLikes}
            viewingPosts={viewingPosts}
            setViewingLikes={setViewingLikes}
            setViewingPosts={setViewingPosts}
            setFilterValues={setFilterValues}
            filterValues={filterValues}
            subCategoryArray={subCategoryArray}
            setSubCategoryArray={setSubCategoryArray}
          />
        ))}
      </div>
    </div>
  );
}

export default Home;
