import { db } from "../firebase";
import {
  doc,
  updateDoc,
  increment,
  collection,
  addDoc,
  query,
  where,
  getDocs,
  deleteDoc,
} from "firebase/firestore";

export const likeVideo = async (videoId, videoData) => {
  const videoRef = doc(db, "videos", videoId);
  const likerRef = collection(db, "videos", videoId, "likers");
  await updateDoc(videoRef, {
    likes: increment(1),
  })
    .then(() => {
      console.log("woohoo");
    })
    .catch((error) => {
      console.log(error.message);
    });
  videoData.userId && (await addDoc(likerRef, videoData));
};

export const unlikeVideo = async (videoId, likerData) => {
  const videoRef = doc(db, "videos", videoId);
  await updateDoc(videoRef, {
    likes: increment(-1),
  })
    .then(() => {
      console.log("woohoo2");
    })
    .catch((error) => {
      console.log(error.message);
    });

  if (likerData.userId) {
    const q = query(
      collection(db, "videos", videoId, "likers"),
      where("userId", "==", likerData.userId),
      where("videoId", "==", videoId)
    );
    const unlikeCollection = await getDocs(q);
    unlikeCollection.forEach((document) => {
      const likerRef = doc(db, "videos", videoId, "likers", document.id);
      deleteDoc(likerRef).then(console.log("deleted likes"));
    });
  }
};
