import React, { useEffect } from "react";
import CreatePostHook from "../../hooks/CreatePostHook";
import "./ProgressBar.css";
import { motion } from "framer-motion";

const ProgressBar = ({ file, setFile, attrs }) => {
  const { progress, url } = CreatePostHook(file, attrs);

  useEffect(() => {
    if (url) {
      setFile(null);
    }
  }, [url, setFile]);

  return (
    <motion.div
      className="progress-bar"
      initial={{ width: 0 }}
      animate={{ width: progress + "%" }}
    ></motion.div>
  );
};

export default ProgressBar;
