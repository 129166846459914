import React from "react";
import { Link } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import "./Header.css";
import Logo from "../../images/bundle_logo.png";

function Header(props) {
  const { backArrowLink } = props;
  return (
    <div className="header">
      <Link
        to={{
          pathname: backArrowLink ? backArrowLink : "/",
        }}
      >
        <ArrowBackIosIcon fontSize="large" className="back" />
      </Link>
      <Link
        to={{
          pathname: "/",
        }}
      >
        <img className="logo" src={Logo} alt="" />
      </Link>
    </div>
  );
}

export default Header;
