import React from "react";
import "./VideoFooter.css";
import Ticker from "react-ticker";

function VideoFooter({ title = "", subTitle, description }) {
  return (
    <div className="videoFooter">
      <div className="videoFooter__text">
        <h3>{title.toUpperCase()}</h3>
        <p>{subTitle}</p>
        <div className="videoFooter__ticker">
          <Ticker mode="smooth" speed={9}>
            {({ index }) => (
              <>
                <p>{description}</p>
              </>
            )}
          </Ticker>
        </div>
      </div>
    </div>
  );
}

export default VideoFooter;
