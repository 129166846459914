import React from "react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { category_main, subcategories } from "../../lookups/categories";
import "./FilterDrawer.css";

function FilterDrawer(props) {
  const {
    open = false,
    onClose = () => {},
    filterValues,
    setFilterValues,
    subCategoryArray,
    setSubCategoryArray,
  } = props;

  const handleCategoryChange = (event) => {
    setFilterValues({
      ...filterValues,
      category: event.target.value,
      category1: "",
    });
    const newSubArray = subcategories[event.target.value];
    setSubCategoryArray(newSubArray);
  };

  const handleCategory1Change = (event) => {
    setFilterValues({ ...filterValues, category1: event.target.value });
  };

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      onOpen={() => {}}
      className="filter_drawer_container"
      BackdropProps={{ invisible: true }}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          backgroundImage: "none",
          maxHeight: "85vh",
        },
      }}
    >
      <div className="filter_drawer_container">
        <div className="filter_drawer_contents">
          <h1>Filter Videos</h1>
          <FormControl fullWidth margin="normal">
            <InputLabel id="demo-simple-select-label">Find a</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={filterValues.category}
              label="Find a"
              onChange={handleCategoryChange}
              MenuProps={{
                transitionDuration: 0,
              }}
            >
              <MenuItem value={``}>All</MenuItem>
              {category_main.map(({ value, label, disabled }, index) => (
                <MenuItem key={index} value={value} disabled={disabled}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {filterValues.category && (
            <FormControl fullWidth margin="normal">
              <InputLabel id="demo-simple-select-label">Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={filterValues.category1}
                label="Type"
                onChange={handleCategory1Change}
                MenuProps={{
                  transitionDuration: 0,
                }}
              >
                <MenuItem value={``}>All</MenuItem>
                {subCategoryArray.map(({ value, label, disabled }, index) => (
                  <MenuItem key={index} value={value} disabled={disabled}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </div>
      </div>
    </SwipeableDrawer>
  );
}

export default FilterDrawer;
