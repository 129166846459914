import React, { useState } from "react";
import { ToggleButtonGroup, ToggleButton } from "@mui/material/";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Badge from "@mui/material/Badge";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import { category_main } from "../../lookups/categories";
import "./BundleList.css";

const style = {
  width: "100%",
};

const itemStyle = {
  flexGrow: 0,
  marginRight: 2,
};

const arrowStyle = {
  position: "absolute",
  right: 5,
};

function BundleList(props) {
  const {
    filterValues,
    setFilterValues,
    likeVideos,
    postVideos,
    setViewingLikes,
    setViewingPosts,
    onClose,
  } = props;
  const [selectedListIsLikes, setSelectedListIsLikes] = useState(true);

  const handleChangeList = (event, newSelectedList) => {
    if (newSelectedList !== null) {
      setSelectedListIsLikes(newSelectedList);
    }
  };

  const handleLikesClick = (value) => {
    setFilterValues({
      ...filterValues,
      category: value,
      category1: "",
    });
    setViewingLikes(true);
    onClose();
  };

  const handlePostsClick = (value) => {
    setFilterValues({
      ...filterValues,
      category: value,
      category1: "",
    });
    setViewingPosts(true);
    onClose();
  };

  return (
    <div className="settingList">
      <ToggleButtonGroup
        style={{
          marginTop: 24,
          marginLeft: "auto",
          marginBottom: 8,
          marginRight: "auto",
        }}
        color="primary"
        value={selectedListIsLikes}
        exclusive
        onChange={handleChangeList}
      >
        <ToggleButton value={true}>My Favorites</ToggleButton>
        <ToggleButton value={false}>My Posts</ToggleButton>
      </ToggleButtonGroup>
      {selectedListIsLikes && (
        <List sx={style} component="nav">
          {category_main.map(({ value, labelPlural }) => (
            <ListItem
              button
              divider
              key={value}
              value={value}
              onClick={() => handleLikesClick(value)}
            >
              <ListItemText sx={itemStyle} primary={labelPlural} />
              <Badge
                badgeContent={
                  likeVideos.filter((likes) => likes.data.category === value)
                    .length
                }
                color="primary"
              />
              <ArrowForwardIosIcon sx={arrowStyle} />
            </ListItem>
          ))}
        </List>
      )}

      {!selectedListIsLikes && (
        <List sx={style} component="nav">
          {category_main.map(({ value, labelPlural }) => (
            <ListItem
              button
              divider
              key={value}
              onClick={() => handlePostsClick(value)}
            >
              <ListItemText sx={itemStyle} primary={labelPlural} />
              <Badge
                badgeContent={
                  postVideos.filter((posts) => posts.data.category === value)
                    .length
                }
                color="primary"
              />
              <ArrowForwardIosIcon sx={arrowStyle} />
            </ListItem>
          ))}
        </List>
      )}
    </div>
  );
}

export default BundleList;
