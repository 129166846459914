import React from "react";
import CreatePost from "./CreatePost";
import { subcategories } from "../../lookups/categories";

function PostJob() {
  return (
    <CreatePost
      category="pro"
      categoryLabel="Local Pro"
      category1Label="Type of Professional"
      category1Options={subcategories.pro}
      category2Label="NA"
      postTitleLabel="Company"
      postSubTitleLabel="Name"
      postDescriptionLabel="Descrition"
      postWebsiteUrlLabel="Website Link"
      postWebsiteUrlLabel2=""
      postAmountTypes={[
        { value: "", label: "" },
        { value: "", label: "" },
      ]}
    />
  );
}

export default PostJob;
