import React, { useState } from "react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { ToggleButtonGroup, ToggleButton } from "@mui/material/";
import LogIn from "../SignUp/LogIn";
import SignUp from "../SignUp/SignUp";
import UserSettings from "../UserSettings/UserSettings";

import "./SignUpDrawer.css";

function LoginDrawer(props) {
  const {
    open = false,
    onClose,
    currentUser,
    trigger,
    filterValues,
    setFilterValues,
    likeVideos,
    postVideos,
    setViewingLikes,
    setViewingPosts,
  } = props;
  const [authType, setAuthType] = useState("signup");

  const handleLogInOrSignUp = (event, newAuthType) => {
    if (newAuthType !== null) {
      setAuthType(newAuthType);
    }
  };

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      onOpen={() => {}}
      className="filter_drawer_container"
      BackdropProps={{ invisible: true }}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          backgroundImage: "none",
          maxHeight: "85vh",
        },
      }}
    >
      <div className="signup_drawer_container">
        <div className="signup_drawer_contents">
          {currentUser ? (
            <UserSettings
              filterValues={filterValues}
              setFilterValues={setFilterValues}
              likeVideos={likeVideos}
              postVideos={postVideos}
              setViewingLikes={setViewingLikes}
              setViewingPosts={setViewingPosts}
              onClose={onClose}
            />
          ) : (
            <div>
              <ToggleButtonGroup
                style={{
                  marginTop: 24,
                  marginLeft: "auto",
                  marginBottom: 8,
                  marginRight: "auto",
                }}
                color="primary"
                value={authType}
                exclusive
                onChange={handleLogInOrSignUp}
              >
                <ToggleButton value="login">Log in</ToggleButton>
                <ToggleButton value="signup">Sign up</ToggleButton>
              </ToggleButtonGroup>

              {authType === "login" ? (
                <LogIn trigger={trigger} />
              ) : (
                <SignUp trigger={trigger} />
              )}
            </div>
          )}
        </div>
      </div>
    </SwipeableDrawer>
  );
}

export default LoginDrawer;
