import React from "react";
import CreatePost from "./CreatePost";
import { subcategories } from "../../lookups/categories";

function PostActivity() {
  return (
    <CreatePost
      category="activity"
      categoryLabel="Activity"
      category1Label="Type"
      category1Options={subcategories.activity}
      category2Label="NA"
      postTitleLabel="Title"
      postSubTitleLabel="Headline"
      postDescriptionLabel="Descrition"
      postWebsiteUrlLabel="Website Link"
      postWebsiteUrlLabel2=""
      postAmountTypes={[
        { value: "", label: "" },
        { value: "", label: "" },
      ]}
    />
  );
}

export default PostActivity;
