import React from "react";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  EmailIcon,
} from "react-share";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import "./ShareDrawer.css";

function ShareDrawer(props) {
  const { open = false, onClose = () => {}, id } = props;
  const shareUrl = `https://bundlefind.com?post_id=${id}`;
  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      onOpen={() => {}}
      className="share_drawer_container"
      BackdropProps={{ invisible: true }}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          backgroundImage: "none",
          maxHeight: "85vh",
        },
      }}
    >
      <div className="filter_drawer_container">
        <div className="share_drawer_contents">
          <h1>Share this Job</h1>
          <div className="share_group">
            <FacebookShareButton url={shareUrl}>
              <FacebookIcon round />
            </FacebookShareButton>
            <LinkedinShareButton url={shareUrl}>
              <LinkedinIcon round />
            </LinkedinShareButton>
            <TwitterShareButton url={shareUrl}>
              <TwitterIcon round />
            </TwitterShareButton>
            <EmailShareButton url={shareUrl}>
              <EmailIcon round />
            </EmailShareButton>
          </div>
        </div>
      </div>
    </SwipeableDrawer>
  );
}

export default ShareDrawer;
