import React from "react";
import { useNavigate } from "react-router-dom";
import { signOut, getAuth } from "firebase/auth";
import { Button } from "@mui/material/";
import BundleList from "./../BundleList/BundleList";
import "./UserSettings.css";

function UserSettings(props) {
  const auth = getAuth();
  const navigate = useNavigate();
  const {
    filterValues,
    setFilterValues,
    likeVideos,
    postVideos,
    setViewingLikes,
    setViewingPosts,
    onClose,
  } = props;

  const handleLogout = () => {
    signOut(auth)
      .then((result) => {
        navigate("/");
        console.log(result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="settings">
      <BundleList
        filterValues={filterValues}
        setFilterValues={setFilterValues}
        likeVideos={likeVideos}
        postVideos={postVideos}
        setViewingLikes={setViewingLikes}
        setViewingPosts={setViewingPosts}
        onClose={onClose}
      />
      <Button
        onClick={handleLogout}
        variant="outlined"
        style={{ marginTop: 16, marginBottom: 8 }}
        size="large"
      >
        Log Out
      </Button>
    </div>
  );
}

export default UserSettings;
