import { cities } from "../lookups/cities";

const getCities = (lat1, lon1, setLocation) => {
  let closestCities = cities.filter(
    (cities) =>
      cities.lat > lat1 - 1.5 &&
      cities.lat < lat1 + 1.5 &&
      cities.lon > lon1 - 1.5 &&
      cities.lon < lon1 + 1.5
  );
  let closestCities1 = [];

  closestCities.forEach((closeCities) =>
    closestCities1.push({
      label: `${closeCities.city}, ${closeCities.state}`,
      geocode: closeCities.geocode,
      city: closeCities.city,
      state: closeCities.state,
      latitude: closeCities.lat,
      longitude: closeCities.lon,
      distanceSortValue:
        Math.abs(lat1 - closeCities.lat) + Math.abs(lon1 - closeCities.lon),
    })
  );
  closestCities1.sort((a, b) => {
    return a.distanceSortValue - b.distanceSortValue;
  });
  setLocation(closestCities1[0]);
};

export const getUserGeolocation = (setLocation) => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        getCities(
          position.coords.latitude,
          position.coords.longitude,
          setLocation
        );
      },
      (error) => {
        console.log(error);
        console.log("hello2");
      }
    );
  } else {
    console.log("Geolocation is not supported by this browser.");
  }
};

export const getIpLocation = (setLocation, setClosestLocation) => {
  fetch("https://geolocation-db.com/json/2d7a1090-a7e0-11ec-bb96-d99740183a81")
    .then((response) => response.json())
    .then((result) => {
      setLocation(result);
      getCities(result.latitude, result.longitude, setClosestLocation);
    })

    .catch((error) => {
      console.log(error);
    });
};

export const getDistanceFromLatLonInMiles = (lat1, lon1, lat2, lon2) => {
  var R = 3959; // Radius of the earth in miles
  var dLat = deg2rad(lat2 - lat1); // deg2rad below
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in miles
  return d;
};

function deg2rad(deg) {
  return deg * (Math.PI / 180);
}
