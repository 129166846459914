import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  query,
  where,
  orderBy,
  limit,
} from "firebase/firestore";
import { db } from "../firebase";

export const getVideos = async (
  setVideos,
  category,
  category1,
  shareId,
  setShareVideo,
  setLoading,
  geocode
) => {
  const videoCollection = collection(db, "videos");

  if (shareId) {
    const shareDoc = doc(db, "videos", shareId);
    const shareDocSnap = await getDoc(shareDoc);

    if (shareDocSnap.exists()) {
      const shareVideoDocument = [
        {
          data: shareDocSnap.data(),
          id: shareDocSnap.id,
        },
      ];
      setShareVideo(shareVideoDocument);
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  }

  setLoading(true);
  const queryConstraints = [];
  if (category !== "") queryConstraints.push(where("category", "==", category));
  if (category1 !== "")
    queryConstraints.push(where("category1", "==", category1));
  if (geocode !== null) queryConstraints.push(where("geocode", "==", geocode));
  const q = query(
    videoCollection,
    ...queryConstraints,
    orderBy("createdAt", "desc"),
    limit(20)
  );

  onSnapshot(q, (snap) => {
    let documents = [];
    snap.forEach((doc) => {
      if (doc.id !== shareId) {
        documents.push({
          data: doc.data(),
          id: doc.id,
        });
      }
    });
    setLoading(false);
    setVideos(documents);
  });
};
