import React from "react";
import Main from "./components/Main";
import { initializeWindowSize } from "./hooks/WindowSizeHook";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import TagManager from "react-gtm-module";
import CssBaseline from "@mui/material/CssBaseline";
import "./App.css";

const tagManagerArgs = {
  gtmId: "GTM-TRTLCZL",
  dataLayer: {
    originalLocation: `${document.location.protocol}//${document.location.hostname}${document.location.pathname}${document.location.search}`,
  },
};

const initialize = (args) => TagManager.initialize(args);
initialize(tagManagerArgs);

function App() {
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      root: {
        backgroundColor: "333333",
      },
      primary: {
        main: "#00F48E",
      },
    },
  });
  initializeWindowSize();
  return (
    <ThemeProvider theme={darkTheme}>
      <div>
        <CssBaseline />
        <Main />
      </div>
    </ThemeProvider>
  );
}

export default App;
