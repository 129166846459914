import React, { useRef, useState, useEffect, useMemo } from "react";
import PlayCircleTwoToneIcon from "@mui/icons-material/PlayCircleTwoTone";
import VolumeOffTwoToneIcon from "@mui/icons-material/VolumeOffTwoTone";
import VideoHeader from "../VideoHeader/VideoHeader";
import VideoFooter from "../VideoFooter/VideoFooter";
import VideoSidebar from "../VideoSidebar/VideoSidebar";
import "./Video.css";

function Video(props) {
  const {
    id,
    data,
    userLiked,
    handleFilterOpen,
    handleLocationOpen,
    handlePostOpen,
    handleOpenSignUp,
    category,
    viewingLikes,
    viewingPosts,
    setViewingLikes,
    setViewingPosts,
    filterValues,
    setFilterValues,
    setSubCategoryArray,
  } = props;
  const [playing, setPlaying] = useState(true);
  const videoRef = useRef(null);
  const [showMuteButton, setShowMuteButton] = useState(false);

  const onVideoPress = () => {
    if (playing && videoRef.current) {
      setShowMuteButton(false);
      if (videoRef.current.muted === true) {
        videoRef.current.muted = false;
      } else {
        videoRef.current.pause();
        setPlaying(false);
      }
    } else {
      playLogic(videoRef.current);
    }
  };

  const playLogic = (ref) => {
    var promise = ref.play();
    if (promise !== undefined) {
      promise
        .catch((error) => {
          setPlaying(false);
        })
        .then(() => {
          setPlaying(true);
          if (ref.muted === true) {
            setShowMuteButton(true);
          } else {
            setShowMuteButton(false);
          }
        });
    }
  };

  const options = useMemo(() => {
    return {
      root: null,
      rootMargin: "0px",
      threshold: 0.3,
    };
  }, []);

  useEffect(() => {
    const callbackFunction = (entries) => {
      const [entry] = entries;
      if (videoRef.current) {
        if (entry.isIntersecting) {
          playLogic(videoRef.current);
        } else {
          videoRef.current.pause();
          setPlaying(false);
          setShowMuteButton(false);
        }
      }
    };

    const observer = new IntersectionObserver(callbackFunction, options);
    const currentTarget = videoRef.current;
    if (currentTarget) observer.observe(currentTarget);
    return () => {
      if (currentTarget) observer.unobserve(currentTarget);
      setPlaying(false);
    };
  }, [videoRef, options]);

  return (
    <div className="video" autoFocus>
      <video
        className="video__player"
        loop
        playsInline
        autoPlay
        muted
        onClick={onVideoPress}
        ref={videoRef}
        onPlay={() => {
          setPlaying(true);
        }}
      >
        <source src={`${data.url}#t=0.001`}></source>
      </video>
      <VideoFooter
        title={data.postTitle}
        subTitle={data.postSubTitle}
        description={data.postDescription}
      />
      <VideoSidebar
        id={id}
        data={data}
        userLiked={userLiked}
        handlePostOpen={handlePostOpen}
        handleOpenSignUp={handleOpenSignUp}
      />
      {!playing && (
        <PlayCircleTwoToneIcon
          onClick={onVideoPress}
          style={{
            height: "80px",
            width: "80px",
          }}
          className="video-play-icon"
        />
      )}
      {showMuteButton && (
        <VolumeOffTwoToneIcon
          onClick={onVideoPress}
          style={{
            height: "50px",
            width: "50px",
          }}
          className="video-play-icon"
        />
      )}
      <VideoHeader
        category={category}
        handleFilterOpen={handleFilterOpen}
        handleLocationOpen={handleLocationOpen}
        setViewingLikes={setViewingLikes}
        setViewingPosts={setViewingPosts}
        viewingLikes={viewingLikes}
        viewingPosts={viewingPosts}
        setFilterValues={setFilterValues}
        filterValues={filterValues}
        setSubCategoryArray={setSubCategoryArray}
        location={data.city}
      />
    </div>
  );
}

export default Video;
