import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import { getUserGeolocation } from "../../hooks/LocationHook";
import { cities } from "../../lookups/cities";
import "./LocationDrawer.css";

const buttonStyle = {
  margin: "10px 0px",
  padding: "10px",
};

function LocationSelection(props) {
  const { location, setLocation, onClose } = props;
  const [locationInputValue, setLocationInputValue] = useState("");
  const [autoCompleteOpen, setAutoCompleteOpen] = useState(false);
  const [locationOptions, setLocationOptions] = useState([]);
  const showLocationSelection = !location.city;

  const handleShowLocationSelection = () => {
    setLocation({
      geocode: null,
      city: "",
      state: "",
      latitude: null,
      longitude: null,
    });
  };

  const handleLocationSelect = (event, value) => {
    if (value) {
      setLocation(value);
      onClose();
    } else
      setLocation({
        geocode: null,
        city: "",
        state: "",
        latitude: null,
        longitude: null,
      });
  };

  useEffect(() => {
    let cityLabels = [];
    cities.forEach((city) =>
      cityLabels.push({
        label: `${city.city}, ${city.state}`,
        geocode: city.geocode,
        city: city.city,
        state: city.state,
        latitude: city.lat,
        longitude: city.lon,
      })
    );
    setLocationOptions(cityLabels);
  }, []);

  useEffect(() => {
    if (location.city) {
    }
  }, [location]);

  return (
    <div>
      {!showLocationSelection && (
        <div className="selected__location">
          <p className="selected__location__title">
            {`${location.city}, ${location.state}`}
          </p>
          <CloseIcon onClick={handleShowLocationSelection} />
        </div>
      )}
      {showLocationSelection && (
        <div>
          <Button
            sx={buttonStyle}
            variant="outlined"
            className="location-btn"
            onClick={() => {
              getUserGeolocation(setLocation);
              onClose();
            }}
          >
            <MyLocationIcon className="btn_location_icon" /> Use My Location
          </Button>
          <p className="location-or">-OR-</p>
          <Autocomplete
            disablePortal
            open={autoCompleteOpen}
            onOpen={() => {
              if (locationInputValue) {
                setAutoCompleteOpen(true);
              }
            }}
            onClose={() => setAutoCompleteOpen(false)}
            inputValue={locationInputValue}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onInputChange={(e, value, reason) => {
              setLocationInputValue(value);
              if (!value) {
                setAutoCompleteOpen(false);
              } else {
                setAutoCompleteOpen(true);
              }
            }}
            onChange={handleLocationSelect}
            options={locationOptions}
            renderInput={(params) => (
              <TextField {...params} label="Search Cities" margin="normal" />
            )}
          />
        </div>
      )}
    </div>
  );
}

export default LocationSelection;
