export const initializeWindowSize = () => {
  window.scrollTo(0, 1);
  const initialVh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${initialVh}px`);

  window.addEventListener("resize", () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  });
};
