import React from "react";
import { Helmet } from "react-helmet";

const Seo = (props) => {
  const { title, description, noZoom = false } = props;
  return (
    <Helmet>
      {noZoom && (
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      )}
      <link
        rel="canonical"
        href={`https://bundlefind.com${window.location.pathname}`}
      />
    </Helmet>
  );
};

export default Seo;
