import React, { useState, useEffect } from "react";
import { TextField, ToggleButtonGroup, ToggleButton } from "@mui/material/";
import Header from "./../Header/Header";
import UploadForm from "../UploadForm/UploadForm";
import NumberFormatCustom from "./NumberFormat";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import LocationSelection from "../LocationDrawer/LocationSelection";
import { useAuth } from "../../firebase";
import "./CreatePost.css";

function CreatePost(props) {
  const {
    category,
    categoryLabel,
    category1Options,
    category1Label,
    category2Label,
    postTitleLabel,
    postSubTitleLabel,
    postDescriptionLabel,
    postWebsiteUrlLabel,
    postWebsiteUrlLabel2,
    postAmountTypes,
  } = props;
  const [postUserId, setPostUserId] = useState("");
  const [category1, setCategory1] = useState("");
  const [category2, setCategory2] = useState("");
  const [postTitle, setPostTitle] = useState("");
  const [postSubTitle, setPostSubTitle] = useState("");
  const [postDescription, setPostDescription] = useState("");
  const [postAmountType, setPostAmountType] = useState(
    postAmountTypes[0].value
  );
  const [postAmount, setPostAmount] = useState(null);
  const [postWebsiteUrl, setPostWebsiteUrl] = useState();
  const [location, setLocation] = useState({
    geocode: null,
    city: "",
    state: "",
    latitude: null,
    longitude: null,
  });
  const currentUser = useAuth();
  const attrs = {
    postUserId: postUserId,
    category: category,
    category1: category1,
    category2: category2,
    postTitle: postTitle,
    postSubTitle: postSubTitle,
    postDescription: postDescription,
    postAmountType: postAmountType,
    postAmount: postAmount,
    postWebsiteUrl: postWebsiteUrl,
    longitude: location.longitude,
    latitude: location.latitude,
    city: location.city,
    state: location.state,
    geocode: location.geocode,
  };

  useEffect(() => {
    currentUser && setPostUserId(currentUser.uid);
  }, [currentUser]);

  const handleChangePostAmountType = (event, newPostAmountType) => {
    if (newPostAmountType !== null) {
      setPostAmountType(newPostAmountType);
    }
  };

  return (
    <div className="create__post__container">
      <Header backArrowLink={currentUser ? "/post" : "/"} />
      <div className="create__post">
        <h1>Post {categoryLabel}</h1>
        <div className="location-box">
          <p className="post-location-title">Location</p>
          <LocationSelection
            location={location}
            setLocation={setLocation}
            onClose={() => {}}
          />
        </div>
        <FormControl fullWidth margin="normal">
          <InputLabel>{category1Label}</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            value={category1}
            label={category1Label}
            onChange={(e) => {
              setCategory1(e.target.value);
            }}
            MenuProps={{
              transitionDuration: 0,
            }}
          >
            {category1Options.map(({ value, label, disabled }, index) => (
              <MenuItem key={index} value={value} disabled={disabled}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          required
          inputProps={{
            autoComplete: "new-password",
            form: {
              autoComplete: "off",
            },
          }}
          label={postTitleLabel}
          margin="normal"
          value={postTitle}
          onChange={(e) => {
            setPostTitle(e.target.value);
          }}
        />
        <TextField
          required
          inputProps={{
            maxLength: 30,
            autoComplete: "new-password",
            form: {
              autoComplete: "off",
            },
          }}
          label={postSubTitleLabel}
          style={{
            marginTop: 16,
          }}
          value={postSubTitle}
          onChange={(e) => {
            setPostSubTitle(e.target.value);
          }}
        />
        <p className="char_left">{30 - postSubTitle.length} characters left</p>
        <TextField
          required
          multiline
          inputProps={{ maxLength: 100 }}
          label={postDescriptionLabel}
          style={{
            marginTop: 16,
          }}
          value={postDescription}
          onChange={(e) => {
            setPostDescription(e.target.value);
          }}
        />
        <p className="char_left">
          {100 - postDescription.length} characters left
        </p>
        {category === "job" ? (
          <ToggleButtonGroup
            style={{
              marginTop: 24,
              marginLeft: "auto",
              marginBottom: 8,
              marginRight: "auto",
            }}
            color="primary"
            value={postAmountType}
            exclusive
            onChange={handleChangePostAmountType}
          >
            {postAmountTypes.map(({ value, label }, index) => (
              <ToggleButton key={index} value={value}>
                {label}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        ) : (
          <div />
        )}
        {category === "job" || category === "home" ? (
          <TextField
            label={`Pay per ${postAmountType}`}
            value={postAmount}
            margin="normal"
            onChange={(e) => {
              setPostAmount(Number(e.target.value));
            }}
            name="numberformat"
            id="formatted-numberformat-input"
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
          />
        ) : (
          <div />
        )}
        {category === "home" ? (
          <TextField
            label={category1 === "rent" ? "Monthly Rent" : "Home Price"}
            value={postAmount}
            margin="normal"
            onChange={(e) => {
              setPostAmount(Number(e.target.value));
            }}
            name="numberformat"
            id="formatted-numberformat-input"
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
          />
        ) : (
          <div />
        )}
        <TextField
          required
          multiline
          label={postWebsiteUrlLabel}
          style={{
            marginTop: 16,
          }}
          value={postWebsiteUrl}
          onChange={(e) => {
            setPostWebsiteUrl(e.target.value);
          }}
        />
        <p className="char_left">{postWebsiteUrlLabel2}</p>
        <UploadForm attrs={attrs} />
      </div>
    </div>
  );
}

export default CreatePost;
