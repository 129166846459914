import React from "react";
import Header from "./../Header/Header";
import UserSettings from "./UserSettings";
import "./UserSettings.css";

function UserSettingsPage() {
  return (
    <div className="settings__container">
      <Header />
      <UserSettings />
    </div>
  );
}

export default UserSettingsPage;
