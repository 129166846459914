import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import FilterListIcon from "@mui/icons-material/FilterList";
import "./VideoHeader.css";
import Logo from "../../images/bundle_logo.png";
import { category_main, subcategories } from "../../lookups/categories";

function VideoHeader(props) {
  const {
    handleFilterOpen,
    handleLocationOpen,
    category,
    viewingLikes,
    viewingPosts,
    setViewingPosts,
    setViewingLikes,
    setFilterValues,
    filterValues,
    setSubCategoryArray,
    location,
  } = props;

  const handleHeaderCategoryChange = (chipValue) => {
    if (filterValues.category === chipValue) {
      setFilterValues({
        ...filterValues,
        category: "",
        category1: "",
      });
      setSubCategoryArray([]);
    } else {
      setFilterValues({
        ...filterValues,
        category: chipValue,
        category1: "",
      });
      const newSubArray = subcategories[chipValue];
      setSubCategoryArray(newSubArray);
    }
  };

  const handleCloseLikes = () => setViewingLikes(false);
  const handleClosePosts = () => setViewingPosts(false);

  return (
    <div className="videoHeader">
      <div className="videoHeader__container">
        <div className="videoHeader__top-bar">
          <img className="videoHeader__logo" src={Logo} alt="" />
          <div className="videoHeader__location" onClick={handleLocationOpen}>
            <LocationOnIcon />
            <p className="videoHeader__location__title">
              {location ? location : "Use My Location"}
            </p>
          </div>
        </div>
        <div className="videoHeader__button__group">
          {filterValues.category || viewingLikes || viewingPosts ? (
            <div
              className="videoHeader__button filter"
              onClick={handleFilterOpen}
            >
              <div className="videoHeader__button__container ">
                <FilterListIcon />
              </div>
            </div>
          ) : (
            <div />
          )}
          {viewingLikes && (
            <div className="videoHeader__button">
              <div className="videoHeader__button__container selected">
                <h4>My Favorites</h4>
                <CloseIcon
                  className="close-filter"
                  onClick={handleCloseLikes}
                />
              </div>
            </div>
          )}
          {viewingPosts && (
            <div className="videoHeader__button">
              <div className="videoHeader__button__container selected">
                <h4>My Posts</h4>
                <CloseIcon
                  className="close-filter"
                  onClick={handleClosePosts}
                />
              </div>
            </div>
          )}
          {category_main
            .filter((categories) => categories.label === category)
            .map(({ value, labelPlural, label, disabled }, index) => (
              <div
                key={value}
                className="videoHeader__button"
                onClick={() => handleHeaderCategoryChange(value)}
              >
                <div
                  className={
                    value === filterValues.category
                      ? "videoHeader__button__container selected"
                      : "videoHeader__button__container "
                  }
                >
                  <h4>{labelPlural}</h4>
                  {filterValues.category ? (
                    <CloseIcon className="close-filter" />
                  ) : (
                    <div />
                  )}
                </div>
              </div>
            ))}
          {category_main
            .filter((categories) => categories.label !== category)
            .map(({ value, labelPlural, label, disabled }, index) => (
              <div
                key={value}
                className="videoHeader__button"
                onClick={() => handleHeaderCategoryChange(value)}
              >
                <div className="videoHeader__button__container ">
                  <h4>{labelPlural}</h4>
                </div>
              </div>
            ))}
          {viewingLikes && (
            <div className="videoHeader__button">
              <div className="videoHeader__button__container">
                <h4>My Favorites</h4>
                <CloseIcon onClick={handleCloseLikes} />
              </div>
            </div>
          )}
          {viewingPosts && (
            <div className="videoHeader__button">
              <div className="videoHeader__button__container">
                <h4>My Posts</h4>
                <CloseIcon onClick={handleClosePosts} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default VideoHeader;
