import {
  query,
  collectionGroup,
  onSnapshot,
  where,
  orderBy,
} from "firebase/firestore";
import { db } from "../firebase";

export const getLikes = async (
  currentUser,
  setLikeVideos,
  category,
  category1,
  setLoading
) => {
  if (currentUser) {
    setLoading(true);
    const userLikesCollection = await query(collectionGroup(db, "likers"));
    const queryConstraints = [where("userId", "==", currentUser.uid)];

    if (category !== "")
      queryConstraints.push(where("category", "==", category));
    if (category1 !== "")
      queryConstraints.push(where("category1", "==", category1));

    const q = query(
      userLikesCollection,
      ...queryConstraints,
      orderBy("createdAt", "desc")
    );

    onSnapshot(q, (snap) => {
      let documents = [];
      snap.forEach((doc) => {
        documents.push({
          data: doc.data(),
          id: doc.data().videoId,
        });
      });
      setLoading(false);
      setLikeVideos(documents);
    });
  }
};
