import {
  collection,
  onSnapshot,
  query,
  where,
  orderBy,
} from "firebase/firestore";
import { db } from "../firebase";

export const getPosts = async (
  currentUser,
  setPostVideos,
  category,
  category1,
  setLoading
) => {
  if (currentUser) {
    const videoCollection = collection(db, "videos");

    setLoading(true);
    const queryConstraints = [where("postUserId", "==", currentUser.uid)];
    if (category !== "")
      queryConstraints.push(where("category", "==", category));
    if (category1 !== "")
      queryConstraints.push(where("category1", "==", category1));
    const q = query(
      videoCollection,
      ...queryConstraints,
      orderBy("createdAt", "desc")
    );

    onSnapshot(q, (snap) => {
      let documents = [];
      snap.forEach((doc) => {
        documents.push({
          data: doc.data(),
          id: doc.id,
        });
      });
      setLoading(false);
      setPostVideos(documents);
    });
  }
};
